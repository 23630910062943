import { AppBar, Box, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { UserHeaderControll } from 'src/components/HeadPanel/UserHeaderControll';
import logo from 'src/images/logo.png';

const Logo = styled('img')({
	height: '50px', // Adjust according to your logo's size
	marginRight: 'auto'
});

const CustomAppBar = styled(AppBar)(({ theme }) => ({
	backgroundColor: 'rgba(255, 255, 255, 0.5)', // Adjust alpha for semi-transparency
	color: theme.palette.text.primary,
	boxShadow: 'none',
	backdropFilter: 'blur(8px)' // Apply blur to the background elements behind the AppBar
}));

export const HeadPanel = (props) => {
	const navigate = useNavigate();

	const handleMainClick = () => {
		navigate('/orders');
	};

	return (
		<CustomAppBar position="static">
			<Toolbar>
				<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
					<Link to="/" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}>
						<Logo src={logo} alt="Oxygen Academy" />
						<Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
							Oxygen Academy
						</Typography>
					</Link>
					<UserHeaderControll />
				</Box>
			</Toolbar>
		</CustomAppBar>
	);
};
