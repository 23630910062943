import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Avatar, Box, IconButton, Menu, MenuItem, Tooltip, Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'src/context/AuthService';

export const UserHeaderControll = () => {
	const [anchorElUser, setAnchorElUser] = useState(null);
	const { user, loading, signOut } = useAuth();
	const navigate = useNavigate();

	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const handleProfileClick = () => {
		setAnchorElUser(null);
		navigate('/user/profile');
	};

	const handleLogout = () => {
		signOut();
	};

	return (
		<Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center' }}>
			<Tooltip title="Открыть настройки">
				<IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
					<Avatar sx={{ bgcolor: 'white', color: 'green', width: 48, height: 48 }} alt={user?.name || 'User'} src={user.avatar}>
						{!user.avatar && user.name[0]}
					</Avatar>
				</IconButton>
			</Tooltip>
			<Menu
				sx={{ mt: '45px' }}
				id="menu-appbar"
				anchorEl={anchorElUser}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
				open={Boolean(anchorElUser)}
				onClose={handleCloseUserMenu}>
				<List>
					<ListItem>
						<ListItemIcon>
							<Avatar sx={{ bgcolor: 'white', color: 'green', width: 34, height: 34 }} alt={user?.name || 'User'} src={user.avatar}>
								{!user.avatar && user.name[0]}
							</Avatar>{' '}
						</ListItemIcon>
						<ListItemText primary={user?.name} secondary={user?.email} />
					</ListItem>
					<MenuItem onClick={handleProfileClick}>
						<Typography textAlign="center">Профиль</Typography>
					</MenuItem>
					<MenuItem onClick={handleLogout}>
						<ListItemIcon>
							<ExitToAppIcon />
						</ListItemIcon>
						<Typography textAlign="center">Выйти</Typography>
					</MenuItem>
				</List>
			</Menu>
		</Box>
	);
};
