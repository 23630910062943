const initialState = {
	trackGroups: []
};

export const trackGroupReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_ALL_TRACK_GROUPS':
			return { ...state, trackGroups: action.payload };
		default:
			return state;
	}
};
