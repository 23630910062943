import { Box, Typography, Link } from '@mui/material';

export const PublicOfferPage = () => {
	return (
		<Box sx={{ p: 4 }}>
			<Typography variant="h2" gutterBottom component="div" sx={{ mb: 4 }}>
				Договор публичной оферты
			</Typography>
			<Typography variant="body1" paragraph>
				<p>
					УТВЕРЖДЕНО
					<br />
					в новой редакции приказом директора ООО «Цитруском» Лубским А.Н.
					<br />
					от №{' '}
				</p>
				<p>
					ПУБЛИЧНЫЙ ДОГОВОР
					<br />
					возмездного оказания услуг
				</p>
				<p>
					Общество с ограниченной ответственностью «Цитруском», именуемое в дальнейшем «Исполнитель», в лице директора Лубского Александра
					Николаевича, действующего на основании Устава, заключает настоящий Публичный договор возмездного оказания услуг (далее по тексту – Договор)
					с любым физическим лицом, именуемым в дальнейшем «Заказчик».
					<br />
					Договор является соглашением о предоставлении на возмездной основе онлайн-доступа к программному обеспечению Исполнителя «Платформа
					справочных материалов» (далее – программное обеспечение, ПО) посредством сайта https://oxygen.academy , являющегося частью программного
					обеспечения, в том числе к Модулям (Блокам), заключаемым путем публичной оферты, и регламентирует порядок оказания таких услуг и
					обязательства Сторон.
					<br />
					Текст настоящего Договора размещается Исполнителем в сети Интернет на веб-сайте по адресу: https://oxygen.academy <br />
					Стороны, руководствуясь статьей 396 Гражданского кодекса Республики Беларусь, заключили настоящий Договор о нижеследующем:
				</p>
				<p>
					1. Предмет договора
					<br />
					1.1. В соответствии с настоящим Договором Исполнитель обязуется предоставить Заказчику онлайн- доступ к программному обеспечению Исполнителя
					«Платформа справочных материалов Lerna» посредством сайта https://oxygen.academy , являющегося частью программного обеспечения, в том числе
					к материалам Модуля (далее также доступ к Модулю), а Заказчик обязуется оплатить Исполнителю вознаграждение.
					<br />
					1.2. В настоящем Договоре используются следующие термины и сокращения:
					<br />
					1.2.1. Модуль – совокупность справочных и практических материалов, объединенных единой темой и направленных на приобретение Заказчиком
					знаний и навыков по этой теме.
					<br />
					1.2.2. Сайт – веб-сайт https://oxygen.academy/ , принадлежащий Исполнителю, являющийся частью программного обеспечения.
					<br />
					1.2.3. Личный кабинет – совокупность защищенных страниц Сайта, созданных в результате регистрации Заказчика и доступных при вводе его
					аутентификационных данных (адреса электронной почты и пароля) в предусмотренные для этого поля на Сайте.
					<br />
					1.2.4. Блок – составная часть Модуля.
					<br />
					1.2.5. Счет – счет, счет-фактура, счет-протокол согласования цены, иной аналогичный документ, которым определяется стоимость услуг, а при
					необходимости – порядок оплаты.
					<br />
					1.2.6. Рассрочка платежа – согласованный Сторонами способ оплаты, при котором оплата за услуги производится в соответствии с Графиком
					платежей, указанным в Счете.
					<br />
					Все термины и определения, используемые в настоящем Договоре, относятся в одинаковой степени к терминам в единственном и множественном
					числе, употребляемом в зависимости от контекста.
					<br />
					1.3. Заказчик может воспользоваться предоставленным онлайн-доступом к Модулю в любое время после оплаты онлайн-доступа к Модулю в
					соответствии с условиями Договора в течение всего периода, указанного на Сайте как период доступа к Модулю.
					<br />
					1.4. Исполнитель в рамках настоящего Договора не оказывает Заказчику индивидуальные консультации, не связанные с предметом договора.
					<br />
					<br />
					2. Порядок заключения и исполнения Договора
					<br />
					2.1. Настоящий Договор является публичным договором, в соответствии с которым Исполнитель принимает на себя обязательство по оказанию услуг
					в отношении неопределенного круга лиц (Заказчиков), обратившихся за их оказанием.
					<br />
					2.2. Размещение текста настоящего Договора на Сайте является публичным предложением (офертой) Исполнителя, адресованным неопределенному
					кругу лиц заключить настоящий Договор (п.2. ст. 407 Гражданского кодекса Республики Беларусь).
					<br />
					2.3. Заключение настоящего Договора производится путем присоединения Заказчика к настоящему Договору, т.е. посредством принятия (акцепта)
					Заказчиком условий настоящего Договора в целом, без каких-либо условий, изъятий и оговорок (ст. 398 Гражданского кодекса Республики
					Беларусь).
					<br />
					2.4. Фактом принятия (акцепта) Заказчиком условий настоящего Договора является оплата Заказчиком заказанных им услуг в порядке и на
					условиях, определенных настоящим Договором. В случае оплаты Заказчиком заказанных им услуг с использованием кредитных средств,
					предоставленных соответствующим банком, фактом принятия (акцепта) Заказчиком условий настоящего Договора является заключение Заказчиком
					соответствующего договора с банком для оплаты услуг Исполнителя (п. 3 ст. 408 Гражданского кодекса Республики Беларусь).
					<br />
					В случае предоставления рассрочки платежа фактом принятия (акцепта) Заказчиком условий настоящего Договора будет признаваться внесение
					первого платежа в соответствии с согласованными сторонами условиями.
					<br />
					2.5. Настоящий Договор при условии соблюдения порядка его акцепта считается заключенным в простой письменной форме (п. 2, п. 3 ст. 404 и п.
					3 ст. 408 Гражданского кодекса Республики Беларусь).
					<br />
					2.6. С помощью Сайта или иным образом Заказчик выбирает Модуль. По получении информации сотрудник Исполнителя направляет Заказчику счет на
					оплату услуг по предоставлению онлайн-доступа. После зачисления оплаты (части оплаты в соответствии с согласованными сторонами условиями) на
					расчетный счет Исполнителя, Заказчику предоставляется онлайн-доступ к Модулю, информация о чем направляется Заказчику посредством
					электронной почты с предоставлением данных для онлайн-доступа к Модулю.
					<br />
					2.7. Модуль состоит из Блоков. Исполнитель предоставляет Заказчику доступ к каждому Блоку поэтапно если поэтапное предоставление
					онлайн-доступов предусмотрено автоматически.
					<br />
					2.8. Доступ к материалам Модуля предоставляется в течение периода, указанного на Сайте. При наличии технической, юридической возможности по
					письменному запросу Заказчика Исполнитель предоставляет Заказчику доступ к Модулю по истечении указанного периода.
				</p>
				<p>
					3. Права и обязанности Сторон
					<br />
					3.1. Исполнитель обязуется:
					<br />
					3.1.1. Предоставить Заказчику доступ к выбранному Модулю после выполнения условий предоставления доступа.
					<br />
					3.1.2. Осуществлять информационную поддержку Заказчика по вопросам оказания услуг и/или функционирования Сайта по рабочим дням с 09:00 до
					18:00 (до 17:00 в пятницу и рабочие дни, являющиеся сокращенными в соответствии с законодательством Республики Беларусь).
					<br />
					Вопросы по поддержке направляются Заказчиком по электронному адресу: oxygen.academy.info@gmail.com
					<br />
					3.1.3. В случае обнаружения ошибок/недоработок в функционировании Сайта, допущенных Исполнителем либо по вине Исполнителя, своими силами и
					за свой счет устранить обнаруженные ошибки/недоработки.
					<br />
					3.1.4. Производить возврат денежных средств, уплаченных Заказчиком, в случаях и на условиях, предусмотренных Договором.
					<br />
					3.2. Исполнитель вправе:
					<br />
					3.2.1. Вносить изменения в содержание Модуля (в целом либо в части отдельных Блоков) без согласования с Заказчиком.
					<br />
					3.2.2. Привлекать третьих лиц для исполнения настоящего Договора без согласования с Заказчиком.
					<br />
					3.2.3. Запрашивать у Заказчика информацию и документы, необходимые для надлежащего исполнения обязательств по настоящему Договору.
					<br />
					3.2.4. Изменять стоимость услуг по Договору с размещением актуальной стоимости на Сайте. При этом изменение стоимости услуг в отношении уже
					оплаченного Заказчиком доступа к Модулю не
					<br />
					<br />
					производится.
					<br />
					3.2.5. Временно приостанавливать работу Сайта для проведения необходимых плановых профилактических и ремонтных работ на технических ресурсах
					Исполнителя.
					<br />
					3.2.6. Производить замену третьих лиц, привлекаемых для исполнения обязательств по Договору.
					<br />
					3.3. Заказчик обязуется:
					<br />
					3.3.1. Оплатить услуги в сроки и на условиях, установленных Договором, согласованных в счетах и/или согласованных Сторонами посредством
					сообщений в Личном кабинете.
					<br />
					3.3.2. Своевременно предоставлять полную и достоверную информацию, необходимую для оказания услуг (в том числе при регистрации на Сайте).
					<br />
					3.3.3. Использовать функционал Сайта и доступ к Модулю исключительно в личных, семейных или иных некоммерческих целях.
					<br />
					3.3.4. Обеспечить конфиденциальность логина и пароля к своему Личному кабинету, не передавать данные доступа третьим лицам.
					<br />
					3.3.5. Незамедлительно уведомить Исполнителя о фактах несанкционированного доступа к своему Личному кабинету.
					<br />
					3.3.6. Не размещать на Сайте или сторонних ресурсах персональные данные других лиц, включая данные доступа к их Личному кабинету на Сайте, а
					равно не использовать и не пытаться получить персональные данные других пользователей.
					<br />
					3.3.7. Не регистрироваться в качестве пользователя от имени или вместо другого лица или регистрировать группу (объединение) лиц или
					юридическое лицо/индивидуального предпринимателя в качестве такого пользователя.
					<br />
					3.3.8. Соблюдать этические нормы при работе с материалами Модуля и/или при обсуждениях материалов Модуля и/или Исполнителя в сети Интернет
					на различных площадках, включая недопущение оставления сообщений в общих чатах, не относящихся к тематике Модуля (недопущение флуда),
					неуважительных высказываний и оскорблений в адрес других пользователей, привлекаемых экспертов/ третьих лиц, сотрудников Исполнителя.
					<br />
					3.3.9. Не использовать программное обеспечение и не осуществлять действия, направленные на нарушение нормального функционирования Сайта, не
					загружать, не хранить, не публиковать, не распространять и не предоставлять доступ или иным образом использовать вирусы, троянские и другие
					вредоносные программы; не использовать без письменного разрешения Исполнителя автоматизированные скрипты (программы) для сбора информации на
					Сайте.
					<br />
					3.4. Заказчик вправе:
					<br />
					3.4.1. Получить доступ к Модулю после выполнения условий предоставления доступа (статья 2 Договора).
					<br />
					3.4.2. Получать информационную поддержку по вопросам, связанным с порядком оказания услуги функционированием Сайта, в течение всего срока
					действия настоящего Договора.
					<br />
					3.4.3. Использовать доступ к Модулю исключительно в некоммерческих целях.
					<br />
					3.4.4. Направлять Исполнителю замечания, предложения и/или отзывы, связанные с предметом настоящего Договора и/или функционированием Модуля
					либо Личного кабинета.
				</p>
				<p>
					4. Стоимость услуг и порядок оплаты
					<br />
					4.1. Стоимость Услуг Исполнителя определяется на основании стоимости Модуля, указанной на Сайте или согласованной в Счете.
					<br />
					4.2. Заказчик производит оплату стоимости услуг Исполнителя на условиях 100% предоплаты единовременно путем перечисления денежных средств на
					расчетный счет Исполнителя, если иные условия не указаны в выставляемом Заказчику счете.
					<br />
					Датой оплаты является дата поступления денежных средств на расчетный счет Исполнителя.
					<br />
					4.3. Оплата Услуг осуществляется Заказчиком в пользу Исполнителя путем перечисления соответствующей суммы денежных средств способами,
					поддерживаемыми Исполнителем. При этом Исполнитель вправе самостоятельно определять перечень таких способов, доводя до Заказчика актуальный
					перечень способов путем размещения его Сайте, либо информируя Заказчика иным способом. Подробные правила оплаты и возврата денежных средств
					содержатся в Приложении № 1 к настоящему Договору.
					<br />
					4.4. Датой оказания услуг считается дата предоставления Заказчику онлайн-доступа к материалам Модуля. Соответственно, с момента
					предоставления онлайн-доступа к соответствующему Модулю (Блоку, составной части Модуля, если Заказчиком оформлена рассрочка платежа) Услуги
					считаются оказанными Заказчику.
					<br />
					4.5. Исполнитель не позднее 10-ого числа месяца, следующего за месяцем оказания услуг, составляет Акт оказанных услуг в одностороннем
					порядке без направления Заказчику в соответствии с действующим законодательством Республики Беларусь.
					<br />
					По просьбе Заказчика, направленной посредством электронной почты, Исполнитель направляет Акт оказанных услуг Заказчику с использованием
					электронных каналов связи. В этом случае Заказчик обязан подписать полученный Акт в течение 5 (пяти) рабочих дней и направить 1 экземпляр
					Исполнителю.
				</p>
				<p>
					5. Ответственность Сторон. Ограничение ответственности
					<br />
					5.1. При нарушении Заказчиком условий Договора, регулирующих права на интеллектуальную собственность либо обязательств о непредоставлении
					третьим лицам доступа к материалам Модуля Заказчик обязан во внесудебном порядке по письменному требованию Исполнителя оплатить штраф в
					размере 25000 (двадцать пять тысяч) белорусских рублей за каждое нарушение.
					<br />
					5.2. В случае нарушения Заказчиком условий настоящего Договора Исполнитель вправе деактивировать доступ Заказчика к Модулю и/или Личному
					кабинету, а также заблокировать доступ Заказчика на Сайт без возврата уплаченных денежных средств.
					<br />
					5.3. Исполнитель не несет ответственности:
					<br />
					- за содержание информации, размещаемой в чатах мессенджеров (WhatsApp, Viber, Telegram), которые создаются по инициативе пользователей,
					включая Заказчика;
					<br />
					- за использование третьими лицами персональных данных, которые Заказчик сообщает в таких чатах;
					<br />
					- за качество соединения с сетью Интернет и функционирование оборудования и программного обеспечения Заказчика;
					<br />
					- за несоответствие технических характеристик оборудования Заказчика требованиям, предъявляемым для воспроизведения материалов Модуля, если
					такие требования установлены;
					<br />
					- за несоответствие Услуг, предусмотренных Договором, ожиданиям Заказчика. Субъективная оценка Заказчика не является объективным критерием
					качества Услуг;
					<br />
					- за ошибки и неточности, допущенные Заказчиком при использовании функционала Сайта (включая, но не ограничиваясь, неверное указание данных
					при формировании заказа на услугу);
					<br />
					- использования (невозможность использования) и какие бы то ни было последствия использования (невозможности использования) Заказчиком
					выбранной им формы оплаты услуг по Договору;
					<br />
					- за действия (бездействие) банков или третьих лиц, участвующих в расчетах при оплате услуги или возврате денежных средств в установленных
					случаях.
					<br />
					5.4. Исполнитель прилагает все разумные усилия, предотвращающие сбои и неполадки в работе Сайта, однако не гарантирует его бесперебойную
					работу, не несет ответственности и не возмещает убытки, возникшие или возникновение которых возможно в связи с техническими сбоями и
					перебоями в работе провайдера, иных лиц или служб, ограничением доступа к сети Интернет или Интернет-ресурсу по любым причинам,
					необеспечением Заказчиком сохранности данных доступа к своему Личному кабинету и другими обстоятельствами, находящимися вне компетенции
					Исполнителя.
					<br />
					5.5. Ни одна из Сторон не будет нести ответственности за полное или частичное неисполнение своих обязательств, если такое неисполнение будет
					являться следствием действия обстоятельств непреодолимой силы (включая, но не ограничиваясь, пожар, наводнение, землетрясение и другие
					стихийные бедствия, забастовки, война и военные действия, эпидемии, пандемии или другие обстоятельства, находящиеся вне контроля Сторон),
					препятствующих исполнению настоящего Договора и возникших после его заключения.
					<br />
					5.6. В случае если Заказчик не исполняет (ненадлежаще исполняет) свои обязательства по Договору, в том числе обязательства, указанные в п.
					4.2. Договора, Исполнитель вправе прекратить действие настоящего Договора путем одностороннего внесудебного отказа от его исполнения
					посредством направления уведомления о таком отказе в электронном виде на адрес электронной почты, на который ранее Исполнителем были высланы
					данные для онлайн-доступа к Модулю. Действие настоящего Договора прекращается в день направления Исполнителем соответствующего уведомления
					или в срок, указанный в уведомлении, а доступ к Модулю деактивируется в тот же срок.
					<br />
					6. Интеллектуальная собственность. Конфиденциальность
					<br />
					6.1. В рамках оказания Исполнителем Услуг Заказчику предоставляется доступ к интеллектуальной собственности Исполнителя и/или третьих лиц, с
					которыми у Исполнителя заключены соответствующие договоры/соглашения (далее по тексту – Интеллектуальная собственность).
					<br />
					К Интеллектуальной собственности относятся результаты интеллектуальной деятельности Исполнителя и/или третьих лиц, включая, но не
					ограничиваясь, Программное обеспечение, LMS (Learning management system), аудиовизуальные материалы, программы для ЭВМ, методические и/или
					справочные материалы, задания, информационные ресурсы.
					<br />
					6.2. Заказчик применительно к Интеллектуальной собственности обязан:
					<br />
					- воздерживаться от любых действий, которые нарушают права Исполнителя и/или третьих лиц на Интеллектуальную собственность, в частности, не
					копировать, не записывать, не воспроизводить, не тиражировать, не распространять в любой форме, не переделывать, не использовать для
					создания производных произведений любую Интеллектуальную собственность без письменного разрешения Исполнителя;
					<br />
					- немедленно сообщать Исполнителю о любых ставших известными фактах нарушения прав интеллектуальной собственности Исполнителя и/или третьих
					лиц;
					<br />
					- не предоставлять свои аутентификационные данные (email, пароль) для доступа в Личный кабинет на Сайте третьим лицам. В случае утраты, а
					также в случаях незаконного получения доступа к аутентификационным данным и/или Личному кабинету Заказчика третьими лицами, Заказчик
					обязуется незамедлительно сообщить об этом Исполнителю, путем направления уведомления по адресу: oxygen.academy.info@gmail.com
					<br />
					До момента отправки указанного уведомления все действия считаются совершенными Заказчиком.
					<br />
					6.3. К конфиденциальной относится любая информация, относящаяся к процессу оказания услуг Исполнителем, неопубликованная Исполнителем в
					открытом доступе и не являющаяся доступной для всеобщего сведения. Заказчик обязуется не разглашать конфиденциальную информацию и иные
					данные, предоставленные Исполнителем в ходе оказания услуг (за исключением общедоступной информации), третьим лицам без предварительного
					письменного согласия Исполнителя.
					<br />
					6.4. Исполнитель имеет право использовать в своих маркетинговых и/или рекламных материалах объекты, созданные Заказчиком в ходе изучения
					материалов Модуля, а также упоминать Заказчика в качестве пользователя Программного обеспечения и клиента.
				</p>
				<p>
					7. Прочие условия
					<br />
					7.1. Заказчик подтверждает, что он является дееспособным и достиг возраста, необходимого в соответствии с законодательством Республики
					Беларусь для совершения сделок с использованием Сайта.
					<br />
					7.2. Заказчик подтверждает, что осведомлен и безоговорочно согласен с тем, что ему может быть отказано в предоставлении Услуги в случаях:
					<br />
					- неподтверждения авторизации оплаты Банком или электронной платежной системой;
					<br />
					- инициации Заказчиком процедуры возврата денежных средств в случаях и порядке, предусмотренном Договором;
					<br />- в иных случаях, предусмотренных законодательством Республики Беларусь или настоящим Договором.
				</p>
				<p>
					8. Заключительные положения
					<br />
					8.1. Настоящий Договор считается заключенным с момента выполнения Заказчиком действий,
					<br />
					предусмотренных п. 2.4. Договора, и действует до исполнения Сторонами своих обязательств.
					<br />
					8.2. Исполнитель вправе изменять условия настоящего Договора, вводить новые условия или приложения к настоящему Договору без
					предварительного уведомления Заказчика. Заказчик, зная о возможности таких изменений, согласен с тем, что они будут производиться. Если
					Заказчик продолжает исполнять настоящий Договор, в том числе продолжает любое использование программного обеспечения, включая Модуль, Сайта
					после таких изменений, это означает его согласие с такими изменениями.
					<br />
					8.3. Текст изменений и/или дополнений настоящего Договора, либо его новая редакция доводится Исполнителем до всеобщего сведения посредством
					размещения соответствующей информации на Сайте.
					<br />
					8.4. Стороны безоговорочно соглашаются с тем, что молчание (отсутствие письменных уведомлений о несогласии с отдельными положениями
					настоящего Договора, в том числе с изменением тарифов) признается согласием и присоединением Заказчика к новой редакции настоящего Договора
					(п. 3ст. 159 Гражданского кодекса Республики Беларусь).
					<br />
					8.5. Настоящий Договор может быть расторгнут по соглашению Сторон.
					<br />
					Каждая из Сторон имеет право отказаться от настоящего Договора в одностороннем порядке, направив другой Стороне уведомление посредством
					Личного кабинета или по электронной почте не позднее чем за 15 (пятнадцать) календарных дней до планируемой даты прекращения.
					<br />
					8.6. В случае если какое-либо из условий настоящего Договора утратит юридическую силу, будет признано незаконным или будет исключено из
					настоящего Договора, это не влечет недействительность остальных условий настоящего Договора, которые сохранят юридическую силу и являются
					обязательными для Сторон.
					<br />
					8.7. Все вопросы, не урегулированные настоящим Договором, разрешаются в соответствии с действующим законодательством Республики Беларусь.
					<br />
					8.8. Все споры, претензии и разногласия, которые могут возникнуть между Сторонами, будут разрешаться путем переговоров. При невозможности
					урегулирования споров указанным путем Стороны обращаются в суд в порядке, предусмотренном действующим законодательством Республики Беларусь,
					с обязательным соблюдением досудебного порядка урегулирования спора.
					<br />
					Сторона, которая полагает, что ее права по Договору нарушены, обязана предъявить претензию второй Стороне. Претензия считается предъявленной
					надлежащим образом, если направлена другой Стороне любым способом, позволяющим установить личность отправителя и факт вручения
					корреспонденции получателю. Факт возврата корреспонденции с указанием на отсутствие получателя не препятствует обращению в суд. Приложение к
					претензии копий документов, обосновывающих и подтверждающих предъявленные требования, если данные документы имеются в наличии у второй
					Стороны, не является обязательным.
					<br />
					Сторона, получившая претензию, в течение 10 (десяти) рабочих дней со дня ее получения письменно уведомляет вторую Сторону о результатах
					рассмотрения. Неполучение ответа либо мотивированных возражений на претензию в установленный срок рассматривается как согласие с претензией
					и изложенными в ней обстоятельствами.
					<br />
					8.9. Стороны признают юридическую силу документов, направленных посредством электронных средств связи либо Личного кабинета на Сайте.
					<br />
					8.10. Заказчик дает свое согласие Исполнителю на обработку персональных данных Заказчика, указанных им при обращении за услугами Исполнителя
					и/или при регистрации на Сайте, а также в Личном кабинете на условиях, предусмотренных Политикой обработки персональных данных, размещенной
					на Сайте.
					<br />
					8.11. Заказчик даёт свое согласие Исполнителю на использование Исполнителем отзывов Заказчика об Исполнителе и оказываемых им Услугах,
					размещенных Заказчиком в сети Интернет, в том числе оставленных Заказчиком в официальных группах Исполнителя в социальных сетях, без
					указания полного имени (фамилии, имени и отчества) Заказчика и без использования изображения Заказчика любым способом, включая, но не
					ограничиваясь, переработку, в том числе сокращение, изменение, не искажающее их смысл, в целях размещения таких отзывов на официальных
					сайтах Исполнителя, на страницах Исполнителя в социальных сетях, в рекламных и иных материалах Исполнителя, включения в тексты
					информационных рассылок клиентам Исполнителя. Настоящее согласие действует с даты заключения Договора. Настоящее согласие может быть
					отозвано Заказчиком в любой момент путём направления письменного заявления по адресу Исполнителя, указанному в Договоре.
					<br />
					8.12. Заказчик дает свое согласие Исполнителю на получение от Исполнителя сообщений рекламного характера, информационных рассылок о
					продуктах, услугах Исполнителя и его партнеров, о рекламных акциях, скидках и специальных предложениях, розыгрышах, конкурсах, опросах на
					адрес электронной почты Заказчика, по номеру телефона Заказчика, в том числе в мессенджерах, включая «Viber», «Telegram», «WhatsApp»,
					посредством почтовой рассылки, SMS-сообщений, push-уведомлений, а также сообщение Заказчику такой информации в устном формате по телефону.
				</p>
				<p>9. Реквизиты Сторон</p>
				<p>
					9.1. Стороны соглашаются реквизитами Заказчика считать информацию, указанную им при регистрации на Сайте и/или оформлении заявки на
					предоставление доступа к Модулю, и/или оплате услуг Исполнителя.
					<br />
					9.2. Реквизиты Исполнителя:
					<br />
					ООО «Цитруском»
					<br />
					220013 г. Минск, 2-ая Шестая Линия, д.1, кв. 6А-8-120
					<br />
					УНП 193699866
					<br />
					Р/с BY46 UNBS 3012 0499 6100 4001 0933
					<br />
					В ЗАО &apos;БСБ БАНК&apos;
					<br />
					г. Минск, Логойский тракт 15-4, БИК UNBSBY2X
					<br />
					<br />
					Приложение № 1<br />
					к Публичному договору возмездного оказания услуг
					<br />
					Правила оплаты и возврата денежных средств
				</p>
				<p>
					Настоящие Правила оплаты и возврата денежных средств (далее - Правила) регулируют отношения между Исполнителем и Заказчиком, связанные с
					оплатой услуг по Договору и возвратом оплаченной стоимости услуг в случае досрочного расторжения Договора.
					<br />
					1. Окончательная стоимость Услуг формируется с учетом выбранного Заказчиком Модуля.
					<br />
					Оплата Услуг осуществляется в безналичном порядке одним из способов, указанных на Сайте, по выбору Заказчика путем зачисления денежных
					средств на счет Исполнителя.
					<br />
					2. При оплате на Сайте или посредством Личного кабинета к оплате принимаются карты VISA, MasterCard, Белкарт.
					<br />
					Осуществление платежей производится в соответствии с Правилами международных платежных систем Visa и MasterCard (далее – платежные системы)
					на принципах соблюдения конфиденциальности и безопасности совершения платежа с использованием современных методов проверки, шифрования и
					передачи данных по закрытым каналам связи. По вопросам правил и порядка осуществления оплаты посредством платежных систем Заказчик
					руководствуется правилами соответствующих платежных систем и обращается напрямую к операторам соответствующих платежных систем за
					разъяснениями и консультациями. Исполнитель настоящим оставляет за собой право оказывать информационную поддержку относительно осуществления
					оплаты Услуг с помощью платежных систем в пределах своих компетенций, однако ничто в настоящем приложении не обязывает его осуществлять
					такую информационную поддержку, отвечать за полноту предоставленной информации или нести иные последствия выбора способа оплаты услуг и/или
					платежной системы Заказчиком.
					<br />
					3. Для оплаты Услуг банковской картой Visa или MasterCard при выборе способа оплаты необходимо нажать кнопку «Оплата банковской картой» и
					для завершения платежа необходимо нажать кнопку
					<br />
					«Оплатить».
					<br />
					4. Оплата заказа осуществляется после перенаправления на защищенную платежную страницу оператора платежной системы и/или соответствующего
					банка для ввода реквизитов банковской карты. Соединение с платежной страницей и передача информации осуществляются в защищенном режиме с
					использованием технологии 3D Secure.
					<br />
					На странице для ввода данных банковской карты Заказчику необходимо ввести данные банковской карты: номер карты, имя владельца карты, срок
					действия карты, трёхзначный код безопасности (CVV2 для VISA или CVC2 для MasterCard). Все необходимые данные пропечатаны на самой карте.
					Трёхзначный код безопасности — это три цифры, находящиеся на обратной стороне карты. Если банк Заказчика поддерживает технологию безопасного
					проведения интернет-платежей MasterCard SecureCode, Verified by Visa, для проведения платежа может потребоваться ввод специального пароля.
					<br />
					5. Реквизиты банковской карты Заказчика не хранятся на сервере Исполнителя или на Сайте, Исполнитель доступа к платежным реквизитам
					Заказчика не имеет.
					<br />
					Конфиденциальность сообщаемой Заказчиком информации, включая персональные данные и платежные реквизиты, обеспечивается оператором
					соответствующей платежной системы и/или банком Заказчика.
					<br />
					Введенная Заказчиком информация, включая платежные реквизиты, данные банковской карты, не предоставляется третьим лицам за исключением
					случаев, предусмотренных законодательством Республики Беларусь.
					<br />
					6. В проведении платежа может быть отказано банком в следующих случаях:
					<br />
					6.1. банковская карта не предназначена для совершения платежей через интернет; Заказчик может узнать об этом, обратившись в обслуживающий
					банк.
					<br />
					6.2. недостаточно средств для оплаты на банковской карте.
					<br />
					Подробнее о наличии средств на банковской карте Заказчик может узнать, обратившись в банк, выпустивший банковскую карту;
					<br />
					6.3. данные банковской карты введены неверно;
					<br />
					6.4. истек срок действия банковской карты.
					<br />
					Срок действия карты, как правило, указан на лицевой стороне карты (это месяц и год, до которого действительна карта).
					<br />
					Подробнее о сроке действия карты Заказчик может узнать, обратившись в банк, выпустивший банковскую карту.
					<br />
					7. При оплате с помощью Apple Pay (если такой способ предусмотрен на Сайте) выберите карту из приложения «Wallet», воспользуйтесь кодпаролем
					или иным способом аутентификации, в зависимости от того, какой способ выбран в приложении.
					<br />
					<br />
					8. При оформлении заказа с помощью Samsung Pay (если такой способ предусмотрен на Сайте) нажмите
					<br />
					«Оплатить Samsung Pay», введите ваш Samsung Account и подтвердите покупку на вашем смартфоне (по отпечатку пальца, радужке или PIN- коду
					Samsung Pay).
					<br />
					9. Исполнитель не контролирует программно-аппаратный комплекс платежной системы и/или банка. Если в результате каких-либо ошибок произошло
					списание денежных средств Заказчика, но платеж не был авторизован платежной системой и/или банком, обязанности по возврату денежных средств
					Заказчику возлагаются на оператора платежной системы и/или соответствующий банк.
					<br />
					10. Возврат уплаченных Заказчиком денежных средств может быть осуществлен в случаях:
					<br />
					10.1. Услуга не оказана в установленный срок по вине Исполнителя;
					<br />
					10.2. Заказчик до начала оказания Услуги обратился с уведомлением о расторжении Договора и возврате денежных средств;
					<br />
					10.3. в случаях, указанных в п. 14 настоящего Приложения.
					<br />
					11. Полный или частичный возврат уплаченных Заказчиком денежных средств производится Исполнителем на основании письменного заявления
					Заказчика, направленного на электронную почту Исполнителя oxygen.academy.info@gmail.com с последующим направлением оригинала на почтовый
					адрес Исполнителя: 220013, г. Минск, 2-ая Шестая Линия, д. 11, кв. 6А-8-120.
					<br />
					Исполнитель вправе потребовать от Заказчика заполнения утвержденной формы заявления о возврате денежных средств.
					<br />
					12. В случае если Заказчик отказывается от Договора до предоставления Исполнителем онлайн- доступа к выбранному Заказчиком Модулю, за
					исключением внесения оплаты на условиях оплаты с использованием кредитных средств, Исполнитель возвращает Заказчику 100% уплаченной суммы.
					<br />
					13. В случае если Заказчик отказывается от Договора до предоставления Исполнителем онлайн- доступа к выбранному Заказчиком Модулю,
					оплаченному с использованием кредитных средств, Исполнитель производит возврат денежных средств в адрес соответствующего банка, за вычетом
					сумм комиссий, удержанных банком с Исполнителя. Исполнитель не несет ответственности за оплату и не возмещает Заказчику сумму начисленных
					процентов за пользование денежными средствами, подлежащих оплате Заказчиком банку в соответствии с заключенным Заказчиком с банком
					договором.
					<br />
					14. В случае если Заказчик отказывается от Договора в течение 30 (тридцати) календарных дней с даты предоставления Исполнителем
					онлайн-доступа к выбранному Заказчиком Модулю, Исполнитель возвращает Заказчику 50% от уплаченной суммы. При этом если онлайн-доступ к
					выбранному Модулю был оплачен с использованием кредитных средств, Исполнитель производит возврат денежных средств в адрес соответствующего
					банка, за вычетом сумм комиссий, удержанных банком с Исполнителя. Исполнитель не несет ответственности за оплату и не возмещает Заказчику
					сумму начисленных процентов за пользование денежными средствами, подлежащих оплате Заказчиком банку в соответствии с заключенным Заказчиком
					с банком договором.
					<br />
					15. В целях однозначного толкования: при отказе Заказчика от услуг, оплаченных с использованием кредитных средств (банковских продуктов –
					потребительских кредитов, рассрочек и пр.), сумма к возврату и расходы Исполнителя рассчитываются от суммы денежных средств, полученных
					Исполнителем от банка в счет оплаты Услуг, оказываемых Заказчику.
					<br />
					16. В случае если Заказчик отказывается от Договора по истечении 30 (тридцати) календарных дней с даты предоставления Исполнителем
					онлайн-доступа к выбранному Заказчиком Модулю, возврат денежных средств не осуществляется.
					<br />
					17. В случаях, предусмотренных Договором, возврат денежных средств производится на счет, с которого была совершена оплата, в течение 14 дней
					с даты получения заявления Заказчика об одностороннем отказе от Договора и направления соответствующего требования. Обязательство
					Исполнителя по возврату денежных средств считается исполненным с момента списания денежных средств с расчетного счёта Исполнителя.
					Конкретный срок зачисления денежных средств на счет Заказчика зависит от банка, эмитировавшего банковскую карту Заказчика, и/или банка,
					предоставившего кредит, если оплата услуг Исполнителя была произведена с использованием кредитных средств.
					<br />
					18. При изменении платежных реквизитов Заказчика он обязуется в заявлении указать банковские реквизиты для возврата денежных средств.
					<br />
					При не указании банковских реквизитов Заказчиком Исполнитель не несет ответственности за некорректное и/или несвоевременное перечисление
					и/или неперечисление денежных средств по платежным реквизитам Заказчика, указанным последним при оплате услуг.
					<br />
					19. Доступ к Модулю прекращается в течение 1 (одного) рабочего дня с даты принятия решения Исполнителем о возврате денежных средств.
					<br />
					20. Исполнитель вправе вернуть Заказчику денежные средства, уплаченные Заказчиком за онлайн-доступ к выбранному Заказчиком Модулю, в случае
					если Заказчик обоснует, что услуга оказана некачественно.
					<br />
					Для рассмотрения обоснованности Заказчик направляет Исполнителю заявление о возврате денежных средств (для этого пункта далее – заявление).
					В заявлении Заказчик должен обосновать свою позицию. К заявлению Заказчик обязан приложить видеозапись выполнения Заказчиком программ
					(упражнений), указанных Модуле. Видеозапись должна содержать выполнения программ (упражнений) полностью. Также на видеозаписи должна быть
					запечатлена информация о выполняемом Заказчиком Модуле.
					<br />
					Заказчик направляет Исполнителю заявление с приложенными видеозаписями на электронную почту Исполнителя или по договоренности с Исполнителем
					иным способом.
					<br />
					Исполнитель рассматривает заявление Заказчика в течение 15 рабочих дней с момента получения.
					<br />
					Исполнитель возвращает Заказчику денежные средства, уплаченные Заказчиком за онлайн-доступ к выбранному Заказчиком Модулю, в течение 15
					рабочих дней со дня принятия решения об удовлетворении заявления Заказчика.
					<br />
					Заказчик вправе просить Исполнителя вернуть денежные средства только за последние 3 недели доступа к программе приобретенной и выполняемой
					Заказчиком непрерывно.
					<br />
				</p>{' '}
			</Typography>

			<Typography variant="body1">
				С текстом полного договора можно ознакомиться по следующей ссылке:{' '}
				<Link href="/oferta" target="_blank" rel="noopener">
					Публичная оферта
				</Link>
				.
			</Typography>
		</Box>
	);
};
