import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { users } from 'src/context/RestService';

function* fetchUsers() {
	try {
		const response = yield call(users.getAll);
		yield put({ type: 'SET_ALL_USERS', payload: response });
	} catch (error) {
		// Если произошла ошибка, отправляем экшен с ошибкой
	}
}
function* fetchCreate(action) {
	try {
		const response = yield call(users.create, action.payload);
		yield put({ type: 'FETCH_ALL_USERS' });
	} catch (error) {
		// Если произошла ошибка, отправляем экшен с ошибкой
	}
}
function* fetchUpdate(action) {
	try {
		const response = yield call(users.update, action.payload);
		yield put({ type: 'FETCH_ALL_USERS' });
	} catch (error) {
		// Если произошла ошибка, отправляем экшен с ошибкой
	}
}
function* fetchDelete(action) {
	try {
		const response = yield call(users.delete, action.payload);
		yield put({ type: 'FETCH_ALL_USERS' });
	} catch (error) {
		// Если произошла ошибка, отправляем экшен с ошибкой
	}
}

export function* userSaga() {
	yield takeEvery('FETCH_ALL_USERS', fetchUsers);
	yield takeEvery('FETCH_CREATE_USERS', fetchCreate);
	yield takeEvery('FETCH_UPDATE_USERS', fetchUpdate);
	yield takeEvery('FETCH_DELETE_USERS', fetchDelete);
}
