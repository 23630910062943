const initialState = {
	products: [],
	updateErrors: {}
};

export const productReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_ALL_PRODUCTS':
			return { ...state, products: action.payload };
		case 'UPDATE_PRODUCT_FAILED':
			return { ...state, updateErrors: action.payload };
		default:
			return state;
	}
};
