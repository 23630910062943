const initialState = {
	senders: []
};

export const senderReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_ALL_SENDERS':
			return { ...state, senders: action.payload };
		default:
			return state;
	}
};
