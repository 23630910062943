import axios from 'axios';

const BASE_HOST = process.env.REACT_APP_API_BASE_URL;

let isRefreshing = false;
let refreshSubscribers = [];

function subscribeTokenRefresh(cb) {
	refreshSubscribers.push(cb);
}

function onRrefreshed(token) {
	refreshSubscribers.forEach((cb) => cb(token));
	refreshSubscribers = [];
}

const axiosInstance = axios.create({
	baseURL: BASE_HOST,
	headers: { 'Content-Type': 'application/json' }
});

const updateStoredTokens = (accessToken, refreshToken, isSubscribe) => {
	const user = JSON.parse(localStorage.getItem('user'));
	user.token = accessToken;
	user.refreshToken = refreshToken;
	user.isSubscribe = isSubscribe;
	localStorage.setItem('user', JSON.stringify(user));
};

axiosInstance.interceptors.request.use(
	(config) => {
		const user = JSON.parse(localStorage.getItem('user'));
		if (user && user.token) {
			config.headers.Authorization = `Bearer ${user.token}`;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

axiosInstance.interceptors.response.use(
	(response) => response,
	async (error) => {
		const {
			config,
			response: { status }
		} = error;
		const originalRequest = config;
		console.log('originalRequest', originalRequest);

		if (status === 401 && !originalRequest._retry) {
			console.log('originalRequest._retry', originalRequest._retry);
			console.log('isRefreshing', isRefreshing);
			if (!isRefreshing) {
				isRefreshing = true;
				originalRequest._retry = true;
				try {
					const { token, refreshToken, isSubscribe } = await refreshTokenRequest();
					updateStoredTokens(token, refreshToken, isSubscribe);
					axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
					isRefreshing = false;
					onRrefreshed(token);

					console.log('return', originalRequest);

					return axiosInstance(originalRequest);
				} catch (refreshError) {
					console.log('Не удалось обновить токен, разлогинивание...', refreshError);
					localStorage.removeItem('user');
					window.location.href = '/login';
					return Promise.reject(refreshError);
				}
			} else {
				return new Promise((resolve) => {
					console.log('Прошли мимо блока', originalRequest);

					subscribeTokenRefresh((token) => {
						originalRequest.headers['Authorization'] = 'Bearer ' + token;
						resolve(axiosInstance(originalRequest));
					});
				});
			}
		} else if (status === 418 && originalRequest.url === '/auth/refresh') {
			console.error('Ошибка обновления токена, разлогинивание...');
			localStorage.removeItem('user');
			window.location.href = '/login';
			return Promise.reject(error);
		}
		console.log('В самом конце бросаем ошибку', error);

		return Promise.reject(error);
	}
);

const sendRequest = async (method, url, value, options = {}) => {
	try {
		const response = await axiosInstance.request({
			method,
			url,
			data: value,
			...options
		});

		return response.data;
	} catch (error) {
		if (!error.response) {
			console.error('Проблема сетевого соединения или сервер недоступен.');
			throw new Error('Сервер временно недоступен. Пожалуйста, попробуйте позже.');
		}
		console.log('Send request ERROR', error);
		throw error;
	}
};

export const courses = {
	getAll: async (value) => {
		return await sendRequest('GET', `/courses`, value);
	},
	getById: async (id) => {
		return await sendRequest('GET', `/courses/${id}`);
	}
};
export const coursesDetails = {
	getAllByCourseId: async (id) => {
		return await sendRequest('GET', `/course_details/${id}`);
	}
};

export const loginIn = async (value) => {
	return await sendRequest('POST', '/auth/login', value);
};

export const register = async (value) => {
	return await sendRequest('POST', '/auth/register', value);
};

export const resetPassword = async (value) => {
	return await sendRequest('POST', '/auth/reset-password', value);
};

export const refreshTokenRequest = async () => {
	const user = JSON.parse(localStorage.getItem('user'));
	if (!user || !user.refreshToken) {
		throw new Error('Отсутствует refreshToken');
	}
	const data = await sendRequest('POST', '/auth/refresh', { refreshToken: user.refreshToken });
	console.log('вернулась дата refreshTokenRequest', data);
	return data;
};

export const users = {
	getAll: async () => {
		return await sendRequest('GET', '/users');
	},
	getById: async (id) => {
		return await sendRequest('GET', `/users/${id}`);
	},
	getUserCourses: async (id) => {
		return await sendRequest('GET', `/users/courses/${id}`);
	},
	create: async (value) => {
		return await sendRequest('POST', `/users`, value);
	},
	update: async (value, id) => {
		return await sendRequest('PUT', `/users/${id}`, value);
	},
	delete: async (id) => {
		return await sendRequest('DELETE', `/users/${id}`);
	}
};

export const subscribe = {
	byEmail: async (value) => {
		return await sendRequest('POST', `/subscript/byemail`, value);
	}
};
