import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { enqueueSnackbar } from 'notistack';
import * as React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Logo } from 'src/components/Logo/Logo';
import { register as registerApi } from 'src/context/RestService';

export const RegisterPage = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const { state } = location;
	const email = state?.email || undefined;

	const handleSubmit = async (event) => {
		event.preventDefault();

		const data = new FormData(event.currentTarget);
		try {
			var result = await registerApi({
				name: data.get('name'),
				email: data.get('email'),
				// phone: data.get('name'),
				newPassword: data.get('newPassword'),
				confirmPassword: data.get('confirmPassword')
			});
			navigate('/register-success', { state: result });
		} catch (error) {
			if (error.response) {
				switch (error.response.status) {
					case 400:
						if (error.response.data && error.response.data.errors) {
							Object.values(error.response.data.errors).forEach((value) => {
								enqueueSnackbar(`${value}`, { variant: 'error' });
							});
						}
						break;
					case 401:
						enqueueSnackbar('Доступ запрещен: Неавторизован', { variant: 'warning' });
						break;
					case 500:
						enqueueSnackbar(`${error.response.data}`, { variant: 'error' });
						break;
					default:
						enqueueSnackbar('Произошла непредвиденная ошибка', { variant: 'error' });
				}
			} else if (error.request) {
				enqueueSnackbar('Ошибка сети: Не удалось выполнить запрос', { variant: 'error' });
			} else {
				enqueueSnackbar('Ошибка: Неизвестная ошибка', { variant: 'error' });
			}
		}
	};

	const purpleColor = '#7B1FA2';
	return (
		<Container component="main" maxWidth="sm">
			<CssBaseline />
			<Box
				sx={{
					marginTop: 8,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					bgcolor: 'background.paper',
					padding: 3
				}}>
				<Logo />
				<Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: '100%' }}>
					<TextField
						size="small"
						margin="normal"
						required
						fullWidth
						id="name"
						label="Ваше имя"
						name="name"
						autoComplete="on"
						autoFocus
						InputProps={{
							style: { borderRadius: 30 },
							sx: {
								'& fieldset': { borderColor: purpleColor }, // changes the border color
								'&:hover fieldset': { borderColor: purpleColor }, // changes the border color on hover
								'&.Mui-focused fieldset': { borderColor: purpleColor } // changes the border color on focus
							} // This is where you style the actual input
						}}
					/>
					<TextField
						defaultValue={email}
						size="small"
						margin="normal"
						required
						fullWidth
						id="email"
						label="Email"
						name="email"
						autoComplete="on"
						InputProps={{
							style: { borderRadius: 30 },
							sx: {
								'& fieldset': { borderColor: purpleColor }, // changes the border color
								'&:hover fieldset': { borderColor: purpleColor }, // changes the border color on hover
								'&.Mui-focused fieldset': { borderColor: purpleColor } // changes the border color on focus
							} // This is where you style the actual input
						}}
					/>
					{/* <TextField
						size="small"
						margin="normal"
						required
						fullWidth
						id="phone"
						label="Телефон"
						name="phone"
						autoComplete="on"
						InputProps={{
							style: { borderRadius: 30 },
							sx: {
								'& fieldset': { borderColor: purpleColor }, // changes the border color
								'&:hover fieldset': { borderColor: purpleColor }, // changes the border color on hover
								'&.Mui-focused fieldset': { borderColor: purpleColor } // changes the border color on focus
							} // This is where you style the actual input
						}}
					/> */}
					<TextField
						size="small"
						margin="normal"
						required
						fullWidth
						name="newPassword"
						label="Пароль"
						type="password"
						id="newPassword"
						autoComplete="off"
						InputProps={{
							style: { borderRadius: 30 },
							sx: {
								'& fieldset': { borderColor: purpleColor }, // changes the border color
								'&:hover fieldset': { borderColor: purpleColor }, // changes the border color on hover
								'&.Mui-focused fieldset': { borderColor: purpleColor } // changes the border color on focus
							} // And also here
						}}
					/>
					<TextField
						size="small"
						margin="normal"
						required
						fullWidth
						name="confirmPassword"
						label="Подтвердите пароль"
						type="password"
						id="confirmPassword"
						autoComplete="off"
						InputProps={{
							style: { borderRadius: 30 },
							sx: {
								'& fieldset': { borderColor: purpleColor }, // changes the border color
								'&:hover fieldset': { borderColor: purpleColor }, // changes the border color on hover
								'&.Mui-focused fieldset': { borderColor: purpleColor } // changes the border color on focus
							} // And also here
						}}
					/>
					<FormControlLabel
						control={<Checkbox name="remember" defaultChecked color="primary" />}
						label="Запомнить меня"
						sx={{
							mt: 2,
							'& .MuiSvgIcon-root': { fill: purpleColor } // changes the checkbox color
						}}
					/>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						sx={{
							mt: 3,
							mb: 2,
							borderRadius: 15,
							backgroundColor: purpleColor,
							'&:hover': { backgroundColor: '#9C27B0' } // a lighter purple for hover, adjust as needed
						}}>
						Зарегистрироваться
					</Button>
					<Grid container justifyContent="flex-end">
						<Grid item>
							<Link to="/login" variant="body2">
								Уже есть аккаунт? Войти
							</Link>
						</Grid>
					</Grid>
				</Box>
			</Box>
			<Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 8, mb: 4 }}>
				© 2024 Oxygen Academy. Все права защищены.
			</Typography>
		</Container>
	);
};
