const initialState = {
	statuses: []
};

export const statusReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_ALL_STATUSES':
			return { ...state, statuses: action.payload };
		default:
			return state;
	}
};
