import React from 'react';
import { useNavigate } from 'react-router-dom';
import logio from 'src/images/logio.png';
import styles from 'src/components/Logo/Logo.module.css';

export const Logo = () => {
	const navigate = useNavigate();

	const handleLogoClick = () => {
		navigate('/');
	};

	return (
		<img
			src={logio}
			alt="Subscription icon"
			onClick={handleLogoClick}
			className={styles.logo} // Применение стиля из CSS Module
		/>
	);
};
