import { Box, Typography, Button, TextField, Chip } from '@mui/material';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { subscribe as subscribeApi } from 'src/context/RestService';

const defaultCourse = {
	title: 'Начинайте у нас',
	category: null,
	price: 20
};

export const CourseSignup = React.forwardRef(({ course }, ref) => {
	const navigate = useNavigate();

	const { title, category, price } = course || defaultCourse;

	const [email, setEmail] = useState('');

	const handleSubmit = async (e) => {
		e.preventDefault();
		navigate('/register', { state: { email } });
	};
	if (course) {
		return (
			<Box ref={ref}>
				<div style={{ padding: '20px', borderRadius: '8px', maxWidth: '400px', margin: '0 auto' }}>
					<Typography variant="h6" align="center" gutterBottom>
						Откройте первую неделю доступа ко{' '}
						<Link to="/" style={{ color: '#ff4081', textDecoration: 'none', fontWeight: 'bold' }}>
							ВСЕМ КУРСАМ{' '}
						</Link>{' '}
						за
					</Typography>
					<Typography variant="h4" align="center" style={{ fontWeight: 'bold', color: '#ff4081' }}>
						1 р.
					</Typography>
				</div>
				<form onSubmit={handleSubmit} noValidate>
					<TextField
						autoFocus
						id="email"
						variant="outlined"
						required
						fullWidth
						label="Email"
						name="email"
						autoComplete="email"
						value={email ?? null}
						onChange={(e) => setEmail(e.target.value)}
						sx={{
							mb: 2,
							'& .MuiOutlinedInput-root': {
								borderRadius: 25
							}
						}}
					/>
					<Button
						type="submit"
						variant="contained"
						color="primary"
						fullWidth
						sx={{
							borderRadius: 25,
							py: 1.5,
							mb: 2
						}}>
						Получить доступ
					</Button>
				</form>

				<Box
					sx={{
						mt: 3,
						p: 3,
						// backgroundColor: '#f9f9f9',
						borderRadius: '8px',
						maxWidth: '500px',
						margin: '0 auto'
						// boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)'
					}}>
					<Typography variant="body1" align="center" gutterBottom>
						Нажимая на кнопку &quot;Получить доступ&quot; вы подтверждаете ознакомление с{' '}
						<Link to="/oferta" style={{ color: '#ff4081', textDecoration: 'none', fontWeight: 'bold' }}>
							Публичной офертой
						</Link>{' '}
						и тарифами, а также даете согласие на обработку персональных данных.
					</Typography>
					<Typography variant="caption" align="center" display="block" sx={{ mt: 2, color: '#777' }}>
						* Первые 7 дней, далее 19.99 р. в неделю.
					</Typography>
				</Box>
			</Box>
		);
	}

	return (
		<Box
			ref={ref}
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center'
			}}>
			<div style={{ padding: '20px', borderRadius: '8px', maxWidth: '400px', margin: '0 auto' }}>
				<Typography variant="h6" align="center" gutterBottom>
					Откройте первую неделю доступа ко{' '}
					<Link to="/" style={{ color: '#ff4081', textDecoration: 'none', fontWeight: 'bold' }}>
						ВСЕМ КУРСАМ{' '}
					</Link>{' '}
					за
				</Typography>
				<Typography variant="h4" align="center" style={{ fontWeight: 'bold', color: '#ff4081' }}>
					1 р.
				</Typography>
			</div>
			<form onSubmit={handleSubmit} noValidate>
				<Box>
					<TextField
						id="email"
						variant="outlined"
						required
						fullWidth
						label="Email"
						name="email"
						autoComplete="email"
						value={email ?? null}
						onChange={(e) => setEmail(e.target.value)}
						sx={{
							mb: 2,
							'& .MuiOutlinedInput-root': {
								borderRadius: 25
							}
						}}
					/>
					<Button
						type="submit"
						variant="contained"
						color="primary"
						fullWidth
						sx={{
							borderRadius: 25,
							py: 1.5,
							mb: 2
						}}>
						Получить доступ
					</Button>
				</Box>
			</form>

			<Box
				sx={{
					mt: 3,
					p: 3,
					// backgroundColor: '#f9f9f9',
					borderRadius: '8px',
					maxWidth: '500px',
					margin: '0 auto'
					// boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)'
				}}>
				<Typography variant="body1" align="center" gutterBottom>
					Нажимая на кнопку &quot;Получить доступ&quot; вы подтверждаете ознакомление с{' '}
					<Link to="/oferta" style={{ color: '#ff4081', textDecoration: 'none', fontWeight: 'bold' }}>
						Публичной офертой
					</Link>{' '}
					и тарифами, а также даете согласие на обработку персональных данных.
				</Typography>
				<Typography variant="caption" align="center" display="block" sx={{ mt: 2, color: '#777' }}>
					* Первые 7 дней, далее 19.99 р. в неделю.
				</Typography>
			</Box>
		</Box>
	);
});

CourseSignup.displayName = 'CourseSignup';
