const initialState = {
	ordersOnGrid: [],
	statuses: [],
	statusesWithOredersCount: [],
	selectedOrder: {},
	select: {
		deliveryType: [
			{ id: 1, name: 'Белпочта>' },
			{ id: 2, name: 'Самовывоз' },
			{ id: 3, name: 'Курьер' }
		],
		managers: [],
		sources: [],
		sender: []
	},
	deliveryTypes: [],
	orderSelector: {
		cat: null,
		search: null,
		sortBy: 'Created',
		sortType: 'asc',
		page: 1,
		pageSize: 10
	},
	notificationsCount: 0,
	audit: []
};

export const orderReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_SELECTED_ORDER_ON_EDIT_ORDER_FORM':
			return { ...state, selectedOrder: action.payload };
		case 'CLEAR_SELECTED_ORDER_ON_EDIT_ORDER_FORM':
			return { ...state, selectedOrder: {} };

		case 'SET_ORDERS_ON_GRID':
			return { ...state, ordersOnGrid: action.payload };

		case 'SET_STATUSES':
			return { ...state, statuses: action.payload };
		case 'CLEAR_STATUSES':
			return { ...state, statuses: [] };

		case 'SET_STATUSES_WITH_ORDERS_COUNT':
			return { ...state, statusesWithOredersCount: action.payload };
		case 'SET_SELECT_DELIVERY_TYPE':
			return { ...state, select: { ...state.select, deliveryType: action.payload } };

		case 'SET_SELECT_MANAGERS':
			return { ...state, select: { ...state.select, managers: action.payload } };
		case 'CLEAR_SELECT_MANAGERS':
			return { ...state, select: { ...state.select, managers: [] } };

		case 'SET_SELECT_SOURCES':
			return { ...state, select: { ...state.select, sources: action.payload } };
		case 'CLEAR_SELECT_SOURCES':
			return { ...state, select: { ...state.select, sources: [] } };

		case 'SET_SELECT_SENDER':
			return { ...state, select: { ...state.select, sender: action.payload } };
		case 'CLEAR_SELECT_SENDER':
			return { ...state, select: { ...state.select, sender: [] } };

		case 'SET_DELIVERU_TYPE':
			return { ...state, deliveryTypes: action.payload };
		case 'CLEAR_DELIVERU_TYPE':
			return { ...state, deliveryTypes: [] };

		case 'UPDATE_SELECTOR':
			return { ...state, orderSelector: { ...state.orderSelector, ...action.payload } };
		case 'SET_NOTIFICATIONS_COUNT':
			return { ...state, notificationsCount: action.payload };

		case 'SET_ORDER_AUDIT':
			return { ...state, audit: action.payload };
		case 'CLEAR_ORDER_AUDIT':
			return { ...state, audit: [] };
		default:
			return state;
	}
};
