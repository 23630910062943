import 'dayjs/locale/ru';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ruRU } from '@mui/x-date-pickers/locales';
import dayjs from 'dayjs';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from 'src/context/AuthService';
import store from 'src/redux/store';
import './styles/global.css';

const theme = createTheme(
	{
		palette: {
			// primary: {
			// 	main: '#7B61FF', // Adjust this color to match your design
			// 	dark: '#5A41CC' // A darker shade for the hover state
			// },
			// background: {
			// 	paper: '#FFFFFF'
			// },
			// text: {
			// 	primary: '#2E2E2E',
			// 	secondary: '#6D6D6D'
			// }
		}
	},
	ruRU
);

export const Provider = ({ children }) => (
	<ThemeProvider theme={theme}>
		<LocalizationProvider adapterLocale="ru" dateAdapter={AdapterDayjs}>
			<BrowserRouter>
				<SnackbarProvider>
					<ReduxProvider store={store}>
						<AuthProvider>{children}</AuthProvider>
					</ReduxProvider>
				</SnackbarProvider>
			</BrowserRouter>
		</LocalizationProvider>
	</ThemeProvider>
);
