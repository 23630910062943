const initialState = {
	notifications: []
};

export const notificationReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_ALL_NOTIFICATIONS':
			return { ...state, notifications: action.payload };
		default:
			return state;
	}
};
