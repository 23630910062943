const initialState = {
	recipients: []
};

export const recipientReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_ALL_RECIPIENTS':
			return { ...state, recipients: action.payload };
		default:
			return state;
	}
};
