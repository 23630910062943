import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { loginIn as login } from 'src/context/RestService';
import { resetState } from 'src/redux/actions/actions';

export function getUser() {
	const userJson = localStorage.getItem('user');
	return userJson ? JSON.parse(userJson) : null;
}

const updateStoredSubscribe = (isSubscribe) => {
	const user = JSON.parse(localStorage.getItem('user'));
	user.isSubscribe = isSubscribe;
	localStorage.setItem('user', JSON.stringify(user));
};

export function AuthProvider(props) {
	const [user, setUser] = useState(getUser());
	const [loading, setLoading] = useState(true);
	const [connection, setConnection] = useState(null);
	const dispatch = useDispatch();

	const handleStorageChange = useCallback(() => {
		setUser(getUser());
	}, []);

	useEffect(() => {
		window.addEventListener('storage', handleStorageChange);
		return () => {
			window.removeEventListener('storage', handleStorageChange);
		};
	}, [handleStorageChange]);

	useEffect(() => {
		setUser(getUser());
		setLoading(false);
	}, []);

	const signIn = async (loginData) => {
		try {
			const result = await login(loginData);
			if (loginData.remember) localStorage.setItem('user', JSON.stringify(result));
			setUser(result);
		} catch (e) {
			localStorage.removeItem('user');
			throw e;
		}
	};

	const signOut = () => {
		localStorage.removeItem('user');
		setUser();
		dispatch(resetState());
	};

	useEffect(() => {
		const createHubConnection = async (userId) => {
			const conn = new HubConnectionBuilder()
				.withUrl(`${process.env.REACT_APP_API_BASE_URL}/universalHub?userId=${userId}`, {
					accessTokenFactory: () => user?.token
				})
				.configureLogging(LogLevel.Information)
				.withAutomaticReconnect()
				.build();

			conn.on('SubscriptionUpdated', (isSubscribed) => {
				console.log('Subscription status updated:', isSubscribed);
				const updatedUser = getUser(); // Получаем актуальные данные пользователя
				updatedUser.isSubscribe = isSubscribed;
				localStorage.setItem('user', JSON.stringify(updatedUser));
				setUser(updatedUser);
			});

			try {
				await conn.start();
				console.log('Connection successful!');
				setConnection(conn);
			} catch (error) {
				console.error('Initial connection failed:', error);
				setTimeout(createHubConnection, 5000); // Попытка переподключиться через 5 секунд
			}
		};

		if (user) {
			createHubConnection(user.id);
		}

		return () => {
			connection?.stop();
		};
	}, [user?.id]);

	return <AuthContext.Provider value={{ user, signIn, signOut, loading, setUser }} {...props} />;
}

const AuthContext = createContext({});
const useAuth = () => useContext(AuthContext);

export { useAuth };
