import { Container, CssBaseline, Box, Typography, Button } from '@mui/material';
import React, { useState, useEffect } from 'react';
import CourseCatalog from 'src/components/CoursePage/CourseCatalog';
import { Logo } from 'src/components/Logo/Logo';
import { useAuth } from 'src/context/AuthService';
import { subscribe as subscribeApi } from 'src/context/RestService';

// Убедитесь, что путь к иконке подписки корректен

export const SubscriptionForm = () => {
	const { user } = useAuth();

	const [customerData, setCustomerData] = useState({
		email: user.email,
		ip: '127.0.0.1'
	});
	const [redirectUrl, setRedirectUrl] = useState('');

	useEffect(() => {
		const fetchIP = async () => {
			try {
				const response = await fetch('https://api.ipify.org?format=json');
				const data = await response.json();
				setCustomerData({ ...customerData, ip: data.ip });
			} catch (error) {
				console.error('Error fetching IP address:', error);
			}
		};

		fetchIP();
	}, []);

	const handleSubmit = async (e) => {
		e.preventDefault();

		const response = await subscribeApi.byEmail(customerData);
		if (response.redirect_url) {
			setRedirectUrl(response.redirect_url);
		} else {
			console.error('Invalid response:', response);
		}
	};

	useEffect(() => {
		if (redirectUrl) {
			console.log(redirectUrl);
			window.location.href = redirectUrl;
		}
	}, [redirectUrl]);

	return (
		<Box>
			<Container component="main" maxWidth="sm">
				<CssBaseline />
				<Box
					sx={{
						marginTop: 8,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						bgcolor: 'background.paper',
						padding: 3
					}}>
					<Logo />
					<Typography component="h1" variant="h5" sx={{ mt: 2, fontWeight: 'bold', textAlign: 'center' }}>
						Получите полный доступ к курсам
					</Typography>
					<Typography variant="body1" sx={{ mt: 2, textAlign: 'center' }}>
						Для того чтобы продолжить пользоваться сервисом и получить полный доступ к курсам, необходимо оформить подписку.
					</Typography>
					<Button
						type="button"
						fullWidth
						variant="contained"
						sx={{
							mt: 3,
							mb: 2,
							borderRadius: 15,
							backgroundColor: '#7B1FA2', // Основной цвет кнопки, скорректируйте по необходимости
							'&:hover': { backgroundColor: '#9C27B0' } // Светлый пурпурный для наведения, скорректируйте по необходимости
						}}
						onClick={handleSubmit}>
						Подписаться
					</Button>
				</Box>
			</Container>
			<CourseCatalog />
			<Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 8, mb: 4 }}>
				© 2024 Oxygen Academy. Все права защищены.
			</Typography>
		</Box>
	);
};
