import { Collapse, Card, CardContent, CardActions, Button, Typography, Box, useTheme, useMediaQuery, Container } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CourseDetailCard } from 'src/components/UserDetalsPage/CourseDetailCard';
import { coursesDetails } from 'src/context/RestService';

export const CourseUserDetailPage = () => {
	const { courseId } = useParams();
	const [courseDetails, setCourseDetails] = useState([]);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	useEffect(() => {
		async function fetchData() {
			try {
				const result = await coursesDetails.getAllByCourseId(courseId);
				setCourseDetails(result);
				const initialOpenDetails = result.reduce((acc, _, index) => {
					acc[index] = false;
					return acc;
				}, {});
			} catch (error) {
				console.error('Ошибка при получении деталей курса:', error.message);
			}
		}

		fetchData();
	}, [courseId]);

	return (
		<Container>
			<Box sx={{ padding: isMobile ? 2 : 4 }}>
				{courseDetails.map((detail) => (
					<CourseDetailCard key={detail.id} detail={detail} />
				))}
			</Box>
		</Container>
	);
};
