import { Card, CardContent, CardActions, Button, Typography, Grid, TextField, Box, Chip, Container, CardMedia, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { courses as coursesApi, users as usersApi } from 'src/context/RestService';

export const CourseUserPage = () => {
	const { userId } = useParams();
	const [courses, setCourses] = useState([]);
	const [filteredCourses, setFilteredCourses] = useState([]);
	const [searchInput, setSearchInput] = useState('');
	const [selectedCategory, setSelectedCategory] = useState('Все');
	const navigate = useNavigate();
	const theme = useTheme(); // Use theme for consistent spacing and styling

	useEffect(() => {
		async function fetchData() {
			try {
				const result = await coursesApi.getAll();
				setCourses(result);
				setFilteredCourses(result); // Initially display all courses
			} catch (error) {
				console.error('Ошибка при получении курсов:', error.message);
			}
		}

		fetchData();
	}, [userId]);

	useEffect(() => {
		const filtered = courses.filter((course) => {
			return (selectedCategory === 'Все' || course.category === selectedCategory) && course.title.toLowerCase().includes(searchInput.toLowerCase());
		});
		setFilteredCourses(filtered);
	}, [searchInput, selectedCategory, courses]);

	const handleCourseClick = (courseId) => {
		navigate(`/users/courses/details/${courseId}`);
	};

	const categories = ['Все', ...new Set(courses.map((course) => course.category))];

	return (
		<Container>
			<Box sx={{ p: 4 }}>
				<Box sx={{ mb: 4 }}>
					<Box sx={{ mb: 2 }}>
						<TextField
							fullWidth
							label="Введите название курса"
							variant="outlined"
							value={searchInput}
							onChange={(e) => setSearchInput(e.target.value)}
							sx={{ mb: 2 }} // Add margin bottom to separate from categories
						/>
					</Box>
					<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
						{categories.map((category) => (
							<Chip
								key={category}
								label={category}
								onClick={() => setSelectedCategory(category)}
								sx={{
									cursor: 'pointer',
									backgroundColor: selectedCategory === category ? 'primary.main' : 'grey.300',
									color: selectedCategory === category ? 'primary.contrastText' : 'text.primary',
									'&:hover': {
										backgroundColor: selectedCategory === category ? 'primary.dark' : 'grey.400'
									},
									borderRadius: theme.shape.borderRadius
								}}
							/>
						))}
					</Box>
				</Box>

				<Grid container spacing={2} justifyContent="center">
					{filteredCourses.map((course) => (
						<Grid item key={course.id} xs={12} sm={6} lg={4} display="flex" justifyContent="center">
							<Card
								sx={{
									display: 'flex',
									maxWidth: 500,
									boxShadow: 15,
									borderRadius: '16px',
									'&:hover': {
										boxShadow: '0 4px 20px 0 rgba(0,0,0,0.12)',
										transform: 'scale(1.05)',
										transition: 'transform 0.2s ease-in-out'
									}
								}}>
								<Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
									<CardContent>
										<Typography gutterBottom variant="h5" component="div">
											{course.title}
										</Typography>
										<Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
											{' '}
											{/* Add margin bottom for spacing */}
											{course.description}
										</Typography>
										<Box display="flex" alignItems="center" gap={1}>
											<Typography
												variant="subtitle2"
												sx={{
													fontWeight: 'bold', // Make the category text bold
													color: theme.palette.secondary.main, // Use theme's secondary color for the text
													backgroundColor: theme.palette.action.hover, // Optional: a subtle background color
													borderRadius: '4px', // Slightly rounded corners for the background
													px: 1, // Horizontal padding inside the background
													py: '2px', // Minimal vertical padding for a compact look
													display: 'inline-block' // Make the Typography behave like an inline element with background
												}}>
												{course.category}
											</Typography>
										</Box>
									</CardContent>
									<CardActions>
										<Button
											size="small"
											onClick={() => handleCourseClick(course.id)}
											variant="contained" // Use the 'contained' variant for a more pronounced look
											sx={{
												borderRadius: '12px', // Apply a larger border radius for a rounded appearance
												padding: '6px 16px', // Adjust padding to ensure text is centrally aligned within the rounded button
												textTransform: 'none', // Optional: prevent uppercase transform if preferred
												fontWeight: 'medium', // Make the button text slightly bolder
												backgroundColor: theme.palette.primary.main, // Use the primary color from the theme
												color: theme.palette.primary.contrastText, // Ensure text color contrasts well with the button background
												'&:hover': {
													backgroundColor: theme.palette.primary.dark // Darken button on hover for visual feedback
												}
											}}>
											Приступить к выполнению
										</Button>
									</CardActions>
								</Box>
								<CardMedia component="img" sx={{ width: 151, objectFit: 'cover' }} image={course.imageUrl} alt={course.title} />
							</Card>
						</Grid>
					))}
				</Grid>
			</Box>
		</Container>
	);
};
