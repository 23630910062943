import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Alert, Grid, Slide, Snackbar } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { enqueueSnackbar } from 'notistack';
import * as React from 'react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Logo } from 'src/components/Logo/Logo';
import { useAuth } from 'src/context/AuthService';

export const AuthenricationPage = () => {
	const { signIn } = useAuth();
	const [error, setError] = useState(null);

	const location = useLocation();

	const { state } = location;
	const email = state?.email || undefined;
	const password = state?.password || undefined;

	const handleSubmit = async (event) => {
		event.preventDefault();

		const data = new FormData(event.currentTarget);
		try {
			await signIn({
				email: data.get('email'),
				password: data.get('password'),
				remember: data.get('remember')
			});
		} catch (error) {
			console.log('Ошибка при входе', error);
			if (error.response) {
				switch (error.response.status) {
					case 400:
						if (error.response.data && error.response.data.errors) {
							Object.values(error.response.data.errors).forEach((value) => {
								enqueueSnackbar(`${value}`, { variant: 'error' });
							});
						} else if (error.response.data) {
							enqueueSnackbar(`${error.response.data}`, { variant: 'error' });
						}
						break;
					case 401:
						enqueueSnackbar('Доступ запрещен: Неавторизован', { variant: 'warning' });
						break;
					case 500:
						enqueueSnackbar(`${error.response.data}`, { variant: 'error' });
						break;
					default:
						enqueueSnackbar('Произошла непредвиденная ошибка', { variant: 'error' });
				}
			} else if (error.request) {
				enqueueSnackbar('Ошибка сети: Не удалось выполнить запрос', { variant: 'error' });
			} else {
				enqueueSnackbar('Ошибка: Неизвестная ошибка', { variant: 'error' });
			}
		}
	};

	const handleCloseError = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setError(null);
	};
	const purpleColor = '#7B1FA2'; // Example purple color, you can change it to the exact color you want

	return (
		<Container component="main" maxWidth="sm">
			<CssBaseline />
			<Box
				sx={{
					marginTop: 8,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					bgcolor: 'background.paper',
					padding: 3
				}}>
				<Logo />
				<Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: '100%' }}>
					<TextField
						defaultValue={email}
						size="small"
						margin="normal"
						required
						fullWidth
						id="email"
						label="Email"
						name="email"
						autoComplete="email"
						autoFocus
						InputProps={{
							style: { borderRadius: 30 },
							sx: {
								'& fieldset': { borderColor: purpleColor }, // changes the border color
								'&:hover fieldset': { borderColor: purpleColor }, // changes the border color on hover
								'&.Mui-focused fieldset': { borderColor: purpleColor } // changes the border color on focus
							} // This is where you style the actual input
						}}
					/>
					<TextField
						defaultValue={password}
						size="small"
						margin="normal"
						required
						fullWidth
						name="password"
						label="Пароль"
						type="password"
						id="password"
						autoComplete="current-password"
						InputProps={{
							style: { borderRadius: 30 },
							sx: {
								'& fieldset': { borderColor: purpleColor }, // changes the border color
								'&:hover fieldset': { borderColor: purpleColor }, // changes the border color on hover
								'&.Mui-focused fieldset': { borderColor: purpleColor } // changes the border color on focus
							} // And also here
						}}
					/>
					<Snackbar open={Boolean(error)} autoHideDuration={3000} onClose={handleCloseError}>
						<Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
							Неправильное имя пользователя или пароль
						</Alert>
					</Snackbar>
					<FormControlLabel
						control={<Checkbox name="remember" defaultChecked color="primary" />}
						label="Запомнить меня"
						sx={{
							mt: 2,
							'& .MuiSvgIcon-root': { fill: purpleColor } // changes the checkbox color
						}}
					/>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						sx={{
							mt: 3,
							mb: 2,
							borderRadius: 15,
							backgroundColor: purpleColor,
							'&:hover': { backgroundColor: '#9C27B0' } // a lighter purple for hover, adjust as needed
						}}>
						Войти
					</Button>
					<Grid container sx={{ mt: 2 }}>
						<Grid item xs>
							<Link to="/change-password" variant="body2">
								Забыли пароль?
							</Link>
						</Grid>
						<Grid item>
							<Link to="/register" variant="body2">
								{'Нет аккаунта? Создать'}
							</Link>
						</Grid>
					</Grid>
				</Box>
			</Box>
			<Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 8, mb: 4 }}>
				© 2024 Oxygen Academy. Все права защищены.
			</Typography>
		</Container>
	);
};
