import { combineReducers } from 'redux';
import { enumReducer } from 'src/redux/reducers/enumReducer';
import { notificationReducer } from 'src/redux/reducers/notificationReducer';
import { orderReducer } from 'src/redux/reducers/orderReducer';
import { positionsReducer } from 'src/redux/reducers/positionsReducer';
import { productCategoryReducer } from 'src/redux/reducers/productCategoryReducer';
import { productReducer } from 'src/redux/reducers/productReducer';
import { recipientReducer } from 'src/redux/reducers/recipientReducer';
import { senderReducer } from 'src/redux/reducers/senderReducer';
import { sourceReducer } from 'src/redux/reducers/sourceReducer';
import { statusReducer } from 'src/redux/reducers/statusReducer';
import { trackGroupReducer } from 'src/redux/reducers/trackGroupReducer';
import { trackNumberReducer } from 'src/redux/reducers/trackNumberReducer';
import { userReducer } from 'src/redux/reducers/userReducer';

const appReducer = combineReducers({
	notificationReducer,
	orderReducer,
	positionsReducer,
	productCategoryReducer,
	productReducer,
	recipientReducer,
	senderReducer,
	sourceReducer,
	statusReducer,
	trackGroupReducer,
	trackNumberReducer,
	userReducer,
	enumReducer
});

const rootReducer = (state, action) => {
	if (action.type === 'RESET_STATE') {
		state = undefined;
	}

	return appReducer(state, action);
};

export default rootReducer;
