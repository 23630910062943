import { Card, CardMedia, CardContent, Typography, Box, TextField, Grid, Button, Chip, CardActions } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { courses as coursesApi } from 'src/context/RestService';

const CourseCatalog = React.forwardRef((props, ref) => {
	const [courses, setCourses] = useState([]);
	const [filter, setFilter] = useState('');
	const [selectedCategory, setSelectedCategory] = useState('');
	const navigate = useNavigate();

	useEffect(() => {
		const fetchCourses = async () => {
			try {
				const response = await coursesApi.getAll();
				setCourses(response);
			} catch (error) {
				console.error('Ошибка при получении данных:', error);
			}
		};

		fetchCourses();
	}, []);

	const handleCategoryClick = (category) => {
		setSelectedCategory(category);
	};

	const handleCourseClick = (courseId) => {
		navigate(`/courses/${courseId}`);
	};

	const filteredCourses = courses.filter((course) => {
		return course.title.toLowerCase().includes(filter.toLowerCase()) && (selectedCategory ? course.category === selectedCategory : true);
	});

	// Get unique categories from courses for filter buttons
	const categories = [...new Set(courses.map((course) => course.category))];
	return (
		<div ref={ref}>
			<Box sx={{ flexGrow: 1, p: 4 }}>
				<TextField
					label="Поиск курса"
					variant="outlined"
					fullWidth
					sx={{ mb: 2 }}
					onChange={(e) => setFilter(e.target.value)}
					InputProps={{
						sx: {
							borderRadius: '20px',
							backgroundColor: 'white',
							boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
						}
					}}
				/>
				<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 3 }}>
					<Chip
						label="Все"
						onClick={() => handleCategoryClick('')}
						variant={selectedCategory === '' ? 'filled' : 'outlined'}
						sx={{
							borderRadius: '20px',
							backgroundColor: selectedCategory === '' ? 'primary.main' : 'transparent',
							color: selectedCategory === '' ? 'white' : 'primary.main',
							'&:hover': {
								backgroundColor: 'primary.light',
								color: 'white'
							}
						}}
					/>
					{categories.map((category, index) => (
						<Chip
							key={index}
							label={category}
							onClick={() => handleCategoryClick(category)}
							variant={selectedCategory === category ? 'filled' : 'outlined'}
							sx={{
								borderRadius: '20px',
								backgroundColor: selectedCategory === category ? 'primary.main' : 'transparent',
								color: selectedCategory === category ? 'white' : 'primary.main',
								'&:hover': {
									backgroundColor: 'primary.light',
									color: 'white'
								}
							}}
						/>
					))}
				</Box>
				<Box sx={{ height: '80vh', overflowY: 'auto', pr: 2, pl: 2 }}>
					<Grid container spacing={2} sx={{ margin: 0, width: '100%', '&:before, &:after': { display: 'none' } }}>
						{filteredCourses.map((course, index) => (
							<Grid item xs={12} sm={6} md={4} lg={3} key={index}>
								<Card
									sx={{
										maxWidth: 345,
										m: 'auto',
										borderRadius: '16px',
										boxShadow: '0 8px 16px rgba(0,0,0,0.25)',
										transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
										'&:hover': {
											transform: 'translateY(-8px)',
											boxShadow: '0 16px 32px rgba(0,0,0,0.25)'
										}
									}}>
									<CardMedia component="img" height="140" image={course.imageUrl} alt={course.title} />
									<CardContent>
										<Typography gutterBottom variant="h5" component="div">
											{course.title}
										</Typography>
										<Typography variant="body2" color="text.secondary">
											{course.description}
										</Typography>
									</CardContent>
									<CardActions>
										<Button size="small" color="primary" onClick={() => handleCourseClick(course.id)}>
											Узнать больше
										</Button>
									</CardActions>
								</Card>
							</Grid>
						))}
					</Grid>
				</Box>
			</Box>
		</div>
	);
});

CourseCatalog.displayName = 'CourseCatalog';

export default CourseCatalog;
