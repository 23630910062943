import { Box, Typography, Link } from '@mui/material';

export const PrivacyPolicyPage = () => {
	return (
		<Box sx={{ p: 4 }}>
			<Typography variant="h2" gutterBottom component="div" sx={{ mb: 4 }}>
				ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ
			</Typography>
			<Typography variant="body1" paragraph>
				<div>
					<p>
						Пожалуйста, внимательно ознакомьтесь с Политикой конфиденциальности до использования настоящего веб-сайта. <br />
					</p>

					<p>21.02.2024</p>

					<p>
						1. Термины и определения <br />
						1.1. Следующие термины, используемые в настоящей Политике конфиденциальности, имеют указанные ниже значения: <br />
						• Политика конфиденциальности — настоящая Политика конфиденциальности в отношении персональных данных пользователей, являющаяся
						внутренним локальным документом, регулирующим деятельность Оператора. <br />
						• Сайт — интернет-сайт, представляющий собой совокупность графических и информационных материалов, расположенный в сети интернет по
						адресу https://oxygen.academy/ <br />
						• Оператор — ООО &quot;ЦИТРУСКОМ&quot;, УНП: 193699866, которое самостоятельно организует и (или) осуществляет Обработку Персональных
						данных, определяет их состав и цели Обработки, действия (операции), совершаемые с Персональными данными. <br />
						• Персональные данные — любая информация, относящаяся прямо или косвенно к определенному или определяемому Пользователю в соответствии
						со ст. 3 настоящей Политики конфиденциальности. <br />
						• Пользователь — любое физическое лицо, использующее Сайт, в том числе Клиент Оператора. <br />
						• Обработка персональных данных (также — «Обработка») — любое действие (операция) или совокупность действий (операций) с Персональными
						данными, совершаемых с использованием средств автоматизации или без их использования. Обработка включает в себя, в том числе: сбор,
						запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение,
						предоставление, доступ), обезличивание, блокирование, удаление, уничтожение Персональных данных. <br />
						• Автоматизированная Обработка — обработка Персональных данных с помощью средств вычислительной техники. <br />
						<br />
						• Уничтожение Персональных данных — действия, в результате которых становится невозможным восстановить содержание Персональных данных и
						(или) в результате которых уничтожаются <br />
						материальные носители Персональных данных. <br />
						• Трансграничная передача персональных данных — передача персональных данных на территорию иностранного государства органу власти
						иностранного государства, иностранному физическому лицу или иностранному юридическому лицу. <br />
						2. Общие положения <br />
						2.1. Настоящая Политика конфиденциальности разработана и применяется ООО &quot;ЦИТРУСКОМ&quot;, УНП: 193699866. <br />
						2.2. Политика конфиденциальности составлена в соответствии с требованиями Закона Республики Беларусь от 7 мая 2021г. № 99-3 &quot;О
						защите персональных данных&quot;. <br />
						2.3. Целью Политики конфиденциальности является обеспечение защиты прав и свобод человека и гражданина при обработке его Персональных
						данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну, соблюдение требований законодательства РБ
						в области Персональных данных. <br />
						2.4. Политика конфиденциальности действует в отношении всей информации, размещенной на Сайте, которую могут получить Пользователи во
						время использования Сайта, его сервисов, через мобильные сайты и приложения, виджеты, а также другие используемые интерактивные
						средства, в которых есть ссылка на данную Политику конфиденциальности. <br />
						2.5. Использование Сайта и направление Оператору Пользователем своих Персональных данных означает безоговорочное согласие Пользователя с
						настоящей Политикой конфиденциальности и указанными в ней условиями обработки его Персональных данных. В случае несогласия с этими
						условиями Пользователь должен воздержаться от использования Сайта. <br />
						2.6. Пользователь обязан ознакомиться с Политикой конфиденциальности до регистрации на Сайте и до оплаты первой подписки. <br />
						2.7. Действие Политики конфиденциальности распространяется исключительно на Сайт и не применяется к другим ресурсам. <br />
						2.8. Политика конфиденциальности опубликована на Сайте. Оператор вправе вносить изменения в Политику конфиденциальности, которые
						вступают в <br />
						<br />
						силу с момента публикации Политики конфиденциальности в новой редакции. <br />
						3. Персональные данные. <br />
						3.1. Для целей настоящей Политики конфиденциальности под Персональными данными понимается: <br />
						3.1.1. персональная информация Пользователя, позволяющая его идентифицировать (фамилия, имя, отчество, почтовый адрес, данные документа,
						удостоверяющего личность), предоставленная <br />
						Пользователем самостоятельно; <br />
						3.1.2. статистические данные об использовании Сайта, которые передаются Сайту автоматически, в том числе: <br />
						3.1.2.1. данные о технических средствах (устройствах): ІР-адрес, вид операционной системы, тип и язык браузера, географическое
						положение, поставщик услуг сети Интернет; <br />
						3.1.2.2. сведения об использовании Сайта (в том числе дата, время и количество посещений, сведения о посещенных страницах, о переходе с
						других ресурсов); <br />
						3.1.2.3. сведения о действиях Пользователя (о направлении отзывов, подписках, запросах); <br />
						3.1.2.4. информация, автоматически получаемая при доступе к Сайту, в том числе с использованием файлов cookies; <br />
						3.1.3. иная информация о Пользователе, обработка которой предусмотрена Пользовательским соглашением, в том числе данные платежного
						средства Пользователя. <br />
						3.2. Субъектами персональных данных являются: <br />
						3.2.1. Пользователи, которые указали данные в формах Сайта, в том числе при регистрации, оформлении подписки, обратной связи, подписки
						на рассылку и прочее; <br />
						3.2.2. Пользователи, просматривающие Сайт. <br />
						3.3. Если Пользователь сообщает Персональные данные третьего лица, то он гарантирует наличие согласия третьего лица на их передачу
						Оператору и последующую Обработку. <br />
						4. Цели Обработки Персональных данных <br />
						4.1. Сайт обрабатывает Персональные данные в следующих целях: <br />
						4.1.1. регистрация на Сайте и предоставление функционала личного кабинета; <br />
						4.1.2. идентификация Пользователя; <br />
						<br />
						4.1.3. предоставление возможности полноценного использования Сайта и предоставляемой Сайтом информации; <br />
						4.1.4. предоставление Пользователю доступа к персонализированным ресурсам Сайта; <br />
						4.1.5. обработка отзывов, запросов по обратной связи, в том числе для обеспечения технической поддержки; <br />
						4.1.6. обработка подписок, оформленных с помощью Сайта; <br />
						4.1.7. ответы на запросы Пользователя; <br />
						4.1.8. установление и поддержание связи с Пользователем и предоставление ему клиентской поддержки; <br />
						4.1.9. информирование Пользователя о товарах, услугах, новостях Оператора и его партнеров, работе Сайта; <br />
						4.1.10. осуществление рекламной деятельности; <br />
						4.1.11. учет предпочтений Пользователя путем предоставления информации о продукции и специальных предложениях, разработанных специально
						для конкретных Пользователей; <br />
						4.1.12. внутренние цели, такие как анализ данных, аудит, разработка новых продуктов, улучшение работы сайта, расширение услуг, выявление
						трендов продаж и определение эффективности рекламных кампаний; <br />
						4.1.13. обеспечение работоспособности и безопасности Сайта. <br />
						4.1.14. проведение маркетинговых акций для Пользователей, в том числе в целях распространения предложений об участии в акциях и
						получении предусмотренных акцией призов или вознаграждений, а также доставки указанных призов или вознаграждений; <br />
						4.1.15. персонализация рассылок, рекламных материалов и иной информации, доводимой до сведения Пользователей; <br />
						4.1.16. проверка, отвечает ли Пользователь условиями проводимых маркетинговых акций, конкурсов и розыгрышей. <br />
						5. Условия Обработки Персональных данных <br />
						5.1. Хранение Персональных данных осуществляется: <br />
						5.1.1. на Сайте с применением Автоматизированной обработки, за исключением случаев, когда неавтоматизированная Обработка необходима в
						связи с исполнением требований законодательства РБ; <br />
						5.1.2. с использованием баз данных, находящихся на территории Республики Беларусь. <br />
						<br />
						5.2. Сроки хранения Персональных данных определены сроком действия Пользовательского соглашения: данные начинают храниться с момента
						акцепта Пользовательского соглашения и до его расторжения либо до отзыва согласия на Обработку. <br />
						5.3. В случае подтверждения факта неточности Персональных данных они подлежат актуализации Оператором. <br />
						5.4. Уничтожение производится при наличии угрозы безопасности Сайта, истечении срока хранения Персональных данных, по запрос у
						Пользователя. <br />
						5.5. При отзыве Пользователем согласия на Обработку вся полученная информация удаляется. <br />
						6. Технические средства и способы Обработки Персональных данных 6.l.Оператор использует следующие технические средства и способы
						Обработки Персональных данных: <br />
						6.1.1. Файлы «cookies», позволяющие веб-серверу передавать данные на компьютер для хранения и других целей. «Cookies» используются в том
						случае, если Пользователь разрешил это в настройках своего браузера (включено сохранение файлов «cookies» и использование технологии{' '}
						<br />
						«JavaScгipt»). Если Пользователь не нуждается в информации, полученной с помощью «cookies», он может отказаться от их использования,
						отключив соответствующую функцию в настройках своего браузера. <br />
						6.l.2.Сбор и обработка обезличенных данных о посетителях Сайта с помощью сервисов интернет-статистики (Яндекс Метрика, Google Аналитика
						и др.) для последующей аналитики Сайта, отслеживания и понимания принципов использования Сайта Пользователями. <br />
						6.1.3. Использование стандартных интернет-технологий, таких как веб- маяк и, пиксельные теги, прозрачные файлы GIF, JavaScгipt,
						локальное хранение данных, но не ограничиваясь ими, в целях отслеживания того, как используется Сайт. Возможно включение веб-маяков в
						рекламу или сообщения, направляемые по электронной почте, для определения, были ли прочитаны эти сообщения. <br />
						7. Конфиденциальность Персональных данных <br />
						7.1. В отношении Персональных данных Пользователя сохраняется их конфиденциальность, кроме случаев добровольного предоставления
						Пользователем информации о себе для общего доступа неограниченному кругу лиц. <br />
						<br />
						7.2. Оператор вправе передать Персональные данные Пользователя третьим лицам в случае, если Пользователь выразил согласие на такие
						действия, если передача необходима для использования Пользователем определенного сервиса либо для исполнения определенного соглашения
						или договора с Пользователем. <br />
						7.3. Оператор вправе предоставить Персональные данные третьим лицам без письменного согласия Пользователя, в следующих случаях: <br />
						7.3.1. в целях предупреждения, пресечения незаконных действий Пользователя и защиты законных интересов Оператора и третьих лиц, а также
						в случаях, установленных законодательством РБ; <br />
						7.3.2. по мотивированному запрос у судебных органов, органов государственной безопасности, прокуратуры, милиции, в следственные органы,
						в иные органы и организации в случаях, установленных нормативными правовыми актами, обязательными для исполнения. <br />
						7.3.3. в целях проведения общего анализа деловой активности или других деловых мероприятий. В этом случае Персональные данные
						Пользователя передаются третьим лицам, включая консультантов, рекламных агентов, инвесторов, в зашифрованной, нечитаемой человеком
						форме. <br />
						7.4. Оператор предупреждает получающих лиц о том, что передаваемые данные могут быть использованы лишь в целях, для которых они
						сообщены. <br />
						7.5. Оператор принимает необходимые организационные и технические меры для защиты Персональных данных от неправомерного или случайного
						доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц. <br />
						7.6. В случае, если у Пользователя есть основания полагать, что безопасность его учетной записи на Сайте находится под угрозой,
						пожалуйста, Пользователь должен незамедлительно сообщить об этом Оператору. <br />
						7.7. Оператор может разглашать обобщенную не личную информацию о действиях Пользователя на Сайте. Оператор вправе комбинировать не
						личную информацию, собранную Оператором, с дополнительной не личной информацией, собранной из других источников. <br />
						8. Меры по обеспечению защиты Персональных данных <br />
						8.1. Принимаемые Оператором меры защиты Персональных данных включают в себя: <br />
						<br />
						8.1.1. правовые меры: <br />
						8.1.1.1. разработка Оператором локальных документов, реализующих требования российского законодательства, в том числе, настоящей
						Политики конфиденциальности и размещение ее на Сайте; <br />
						8.1.1.2. отказ от любых способов Обработки, не соответствующих целям, установленных Политикой конфиденциальности; <br />
						8.1.2. организационные меры: <br />
						8.1.2.1. назначение лица, ответственного за организацию Обработки; <br />
						8.1.2.2. ограничение состава работников Оператора, имеющих доступ к Персональным данным, и организацию разрешительной системы доступа к
						ним; <br />
						8.1.2.3. инструктаж и ознакомление работников Оператора, осуществляющих Обработку, с нормативными правовыми актами, с локальными актами
						Оператора, регламентирующими порядок работы и защиты Персональных данных; <br />
						8.1.2.4. проведение периодических проверок в целях осуществления внутреннего контроля соответствия Обработки требованиям
						законодательства РБ; <br />
						8.1.3. технические меры: <br />
						8.1.3.1. предотвращение несанкционированного доступа к системам, в которых хранятся Персональные данные; <br />
						8.1.3.2. резервирование и восстановление Персональных данных, работоспособности технических средств и программного <br />
						обеспечения, средств защиты информации в информационных системах Персональных данных; <br />
						8.1.3.3. иные необходимые меры безопасности. <br />
						9. Ограничение ответственности <br />
						9.1. В случае утраты или разглашения конфиденциальных Персональных данных Оператор не несет ответственности, если указанные Персональные
						данные: <br />
						9.1.1. стали публичным достоянием до их утраты или разглашения; <br />
						9.1.2. были получены третьей стороной до момента их получения Оператором; <br />
						9.1.3. были разглашены с согласия Пользователя; <br />
						<br />
						9.1.4. были использованы нецелевым образом по причине технических неполадок в программном обеспечении, серверах или компьютерных сетях,
						находящихся вне контроля Оператора. <br />
						10. Трансграничная передача персональных данных <br />
						10.1. Трансграничная передача персональных данных должна осуществляться с соблюдением требований в соответствии с требованиями Закона
						Республики Беларусь от 07.05.2021 № 99-3 «О защите персональных данных». <br />
						10.2. <br />
						10.3. Допускается трансграничная передача только тех персональных данных Пользователей, в отношении которых субъектом персональных
						данных было дано согласие. <br />
						10.4. При трансграничной передаче персональных данных Пользователей в качестве источника персональных данных должна использоваться база
						данных, расположенная на территории Республики Беларусь. 10.4. Базами данных, расположенными на территории Республики Беларусь, являются
						места хранения обрабатываемых Оператором персональных данных Пользователей (Первичная база данных). <br />
						10.5. Персональные данные Пользователей, переданные для обработки в базу данных, расположенную за пределами территории Республики
						Беларусь, должны образовывать копию Первичной базы данных. Объем содержащихся в такой базе персональных данных Пользователей не может
						быть больше объема, содержащегося в Первичной базе данных. <br />
						10.6. Обработка персональных данных Пользователей, содержащихся только в базе данных, расположенной за пределами территории Республики
						Беларусь, не допускается. <br />
						10.7. Обработка персональных данных Пользователей, содержащихся в копии базы данных, ранее переданной за пределы Республики Беларусь,
						должна осуществляться с учетом принципов целеполагания, определенных ст. 4 закона РБ от 07.05.2021 № 99-3 «О персональных данных», при
						этом любые действия, связанные с изменением состава или объема обрабатываемых персональных данных, подлежат первоначальной реализации с
						использованием Первичных баз данных, находящихся на территории Республики Беларусь, с последующей актуализацией персональных данный,
						содержащихся в копии базы данных, находящейся за пределами Республики Беларусь. <br />
						11. Применимое право и разрешение споров <br />
						11.1. Во всем, что не урегулировано настоящей Политикой конфиденциальности, Пользователи и Оператор руководствуются <br />
						<br />
						законодательством Республики Беларусь и ратифицированными Республикой Беларусь международными договорами и соглашениями. <br />
						11.2. В случае возникновения любых споров или разногласий, связанных с исполнением настоящего Соглашения, стороны приложат все усилия
						для их разрешения путем проведения переговоров. <br />
						11.3. В случае возникновения каких-либо претензий, Пользователь должен обратиться к Оператору по адресу электронной почты
						oxygen.academy.info@gmail.com <br />
						11.4. В случае, если споры не будут разрешены путем переговоров, споры подлежат разрешению в государственном суде Республики Беларусь в
						соответствии с подсудностью, установленной законодательством Республики Беларусь. <br />
					</p>
				</div>
			</Typography>
			<Typography variant="body1">
				Полный текст политики конфиденциальности доступен по следующей ссылке:{' '}
				<Link href="/privacy-policy" target="_blank" rel="noopener">
					Политика конфиденциальности
				</Link>
				.
			</Typography>
		</Box>
	);
};
