import { Card, CardContent, CardActions, Button, Typography, Box, Collapse } from '@mui/material';
import React, { useState } from 'react';
import ReactPlayer from 'react-player';

const VideoPlayer = ({ url, imageUrl }) => (
	<Box sx={{ display: 'flex', justifyContent: 'center', mb: 2, overflow: 'hidden', width: '100%' }}>
		<ReactPlayer
			url={url}
			controls
			style={{ maxWidth: '100%', maxHeight: '100%' }} // Ensure the video is responsive and maintains aspect ratio
			config={{
				file: {
					attributes: {
						poster: imageUrl || 'path/to/default/poster.jpg',
						onContextMenu: (e) => e.preventDefault(),
						controlsList: 'nodownload' // Disables download button
					}
				}
			}}
		/>
	</Box>
);

const ImageDisplay = ({ imageUrl, stepOrder }) => (
	<Box sx={{ display: 'flex', justifyContent: 'center', mb: 2, overflow: 'hidden', width: '100%' }}>
		<img src={imageUrl} alt={`Изображение шага ${stepOrder}`} style={{ maxWidth: '100%', height: 'auto', objectFit: 'cover' }} />
	</Box>
);

export const CourseDetailCard = ({ detail }) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleToggle = () => setIsOpen(!isOpen);

	return (
		<Card sx={{ mb: 2, boxShadow: 3, width: '100%' }}>
			{' '}
			{/* Ensure the card takes the full width */}
			<CardContent>
				<Typography variant="h6" gutterBottom>
					Занятие {detail.stepOrder}
				</Typography>
				{detail.text && <Typography variant="body1">{detail.text}</Typography>}
			</CardContent>
			<CardActions>
				<Button size="small" onClick={handleToggle}>
					{isOpen ? 'Скрыть видео' : 'Показать видео'}
				</Button>
			</CardActions>
			<Collapse in={isOpen} timeout="auto" unmountOnExit>
				<CardContent>
					{detail.videoUrl ? (
						<VideoPlayer url={detail.videoUrl} imageUrl={detail.imageUrl} />
					) : detail.imageUrl ? (
						<ImageDisplay imageUrl={detail.imageUrl} stepOrder={detail.stepOrder} />
					) : null}
					{detail.additionalContent && (
						<Typography variant="body2" color="text.secondary">
							{detail.additionalContent}
						</Typography>
					)}
				</CardContent>
			</Collapse>
		</Card>
	);
};
