import { composeWithDevTools } from '@redux-devtools/extension';
import { applyMiddleware, legacy_createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from 'src/redux/reducers/rootReducer';
import rootSaga from 'src/redux/sagas/rootSaga';

const sagaMiddleware = createSagaMiddleware();

const store = legacy_createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(rootSaga);

export default store;
