import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { refreshTokenRequest } from 'src/context/RestService';

export const CheckSubscribe = () => {
	const navigate = useNavigate();

	const refreshAndRedirect = async () => {
		try {
			const result = await refreshTokenRequest();
			const user = JSON.parse(localStorage.getItem('user'));
			const updatedUser = {
				...user,
				token: result.token,
				refreshToken: result.refreshToken,
				isSubscribe: result.isSubscribe
			};
			localStorage.setItem('user', JSON.stringify(updatedUser));

			window.dispatchEvent(new Event('storage'));
		} catch (error) {
			console.error('Error refreshing token:', error);
		}
		navigate('/');
	};

	useEffect(() => {
		refreshAndRedirect();
	}, [navigate]);

	return null;
};
