import 'dayjs/locale/ru';
import { CircularProgress } from '@mui/material';
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AuthenricationPage } from 'src/components/Authentication/AuthenticationPage';
import { ChangePasswordPage } from 'src/components/Authentication/ChangePasswordPage';
import { ChangePasswordSuccessPage } from 'src/components/Authentication/ChangePasswordSuccessPage';
import { RegisterPage } from 'src/components/Authentication/RegisterPage';
import { RegisterSuccessPage } from 'src/components/Authentication/RegisterSuccessPage';
import { CheckSubscribe } from 'src/components/CheckSubscribe';
import CourseCatalog from 'src/components/CoursePage/CourseCatalog';
import { CoursePage } from 'src/components/CoursePage/CoursePage';
import { HeadPanel } from 'src/components/HeadPanel/HeadPanel';
import { Header, MainPage } from 'src/components/MainPage/MainPage';
import { PrivacyPolicyPage } from 'src/components/PrivacyPolicyPage';
import { PublicOfferPage } from 'src/components/PublicOfferPage';
import { SubscriptionForm } from 'src/components/SubscriptionForm';
import { UserAgreementPage } from 'src/components/UserAgreementPage';
import { CourseUserDetailPage } from 'src/components/UserDetalsPage/CourseUserDetailPage';
import { CourseUserPage } from 'src/components/UserDetalsPage/CourseUserPage';
import { ProfileSettings } from 'src/components/UserDetalsPage/ProfileSettings';
import { useAuth } from 'src/context/AuthService';

function App() {
	const { loading } = useAuth();
	const user = JSON.parse(localStorage.getItem('user'));

	if (loading) {
		return <CircularProgress />;
	}
	if (user && !user.isSubscribe) {
		return (
			<div>
				<HeadPanel />
				<Routes>
					<Route path="*" element={<Navigate replace to="/subscription" />} />
					<Route path="/subscription" element={<SubscriptionForm />} />
					<Route path="/check-subscribe" element={<CheckSubscribe />} />
				</Routes>
			</div>
		);
	}
	if (user) {
		const path = `/user/${user.id}`;
		return (
			<div>
				<HeadPanel />
				<Routes>
					<Route path="*" element={<Navigate replace to={path} />} />
					<Route path="/user/:userId" element={<CourseUserPage />} />
					<Route path="/users/courses/details/:courseId" element={<CourseUserDetailPage />} />
					<Route path="/user/profile" element={<ProfileSettings />} />
				</Routes>
			</div>
		);
	}

	return (
		<div>
			<Header />
			<Routes>
				<Route path="*" element={<MainPage />} />
				<Route path="/catalog" element={<CourseCatalog />} />
				<Route path="/courses/:courseId" element={<CoursePage />} />
				<Route path="/login" element={<AuthenricationPage />} />
				<Route path="/register" element={<RegisterPage />} />
				<Route path="/register-success" element={<RegisterSuccessPage />} />
				<Route path="/change-password" element={<ChangePasswordPage />} />
				<Route path="/password-change-success" element={<ChangePasswordSuccessPage />} />

				<Route path="/oferta" element={<PublicOfferPage />} />
				<Route path="/user-agreement" element={<UserAgreementPage />} />
				<Route path="/konfidence" element={<PrivacyPolicyPage />} />
			</Routes>
		</div>
	);
}
export default App;
