const initialState = {
	positions: []
};

export const positionsReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_ALL_POSITIONS':
			return { ...state, positions: action.payload };
		default:
			return state;
	}
};
