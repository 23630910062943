import { Container, CssBaseline, Box, Typography, Button } from '@mui/material';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Logo } from 'src/components/Logo/Logo';

export const RegisterSuccessPage = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const { state } = location;
	const userName = state?.name || 'Пользователь';

	const handleGoToLogin = () => {
		navigate('/login', { state: { email: state.email, password: state.passwordHash } });
	};

	return (
		<Container component="main" maxWidth="sm">
			<CssBaseline />
			<Box
				sx={{
					marginTop: 8,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					bgcolor: 'background.paper',
					padding: 3
				}}>
				<Logo />
				<Typography component="h1" variant="h5" sx={{ mt: 2, fontWeight: 'bold' }}>
					Добро пожаловать, {userName}!
				</Typography>
				<Typography component="h2" variant="h6" sx={{ mt: 1 }}>
					Регистрация прошла успешно!
				</Typography>
				<Typography variant="body1" sx={{ mt: 2, textAlign: 'center' }}>
					Ваш аккаунт был успешно создан. Теперь вы можете войти, используя свои учетные данные.
				</Typography>
				<Button
					type="button"
					fullWidth
					variant="contained"
					sx={{
						mt: 3,
						mb: 2,
						borderRadius: 15,
						backgroundColor: '#7B1FA2', // Основной цвет, использованный в вашей форме
						'&:hover': { backgroundColor: '#9C27B0' } // Светлый пурпурный для наведения, скорректируйте по необходимости
					}}
					onClick={handleGoToLogin}>
					Перейти на страницу входа
				</Button>
			</Box>
			<Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 8, mb: 4 }}>
				© 2024 Oxygen Academy. Все права защищены.
			</Typography>
		</Container>
	);
};
