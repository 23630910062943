import { Box, Typography, Chip, CircularProgress, useTheme } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { CourseSignup } from 'src/components/CoursePage/CourseSignup';
import { courses as coursesApi } from 'src/context/RestService';

export const CoursePage = () => {
	const { courseId } = useParams();
	const [course, setCourse] = useState(null);
	const theme = useTheme();
	const courseSignupRef = useRef(null);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const result = await coursesApi.getById(courseId);
				setCourse(result);
			} catch (error) {
				console.error('Ошибка при получении деталей курса:', error.message);
			}
		};

		fetchData();
	}, [courseId]);

	if (!course) {
		return (
			<Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
				<CircularProgress />
			</Box>
		);
	}

	return (
		<Box
			sx={{
				position: 'relative',
				minHeight: '80vh',
				backgroundImage: `url(${course.imageUrl})`,
				backgroundSize: 'cover',
				backgroundPosition: 'center',
				p: { xs: 3, md: 6 }
			}}>
			{/* Content Overlay */}
			<Box
				sx={{
					position: 'relative',
					maxWidth: 1200,
					mx: 'auto',
					backgroundColor: 'rgba(255, 255, 255, 0.8)',
					borderRadius: theme.shape.borderRadius,
					boxShadow: theme.shadows[4],
					p: { xs: 3, md: 6 }
				}}>
				<Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 4, alignItems: 'center' }}>
					<Box sx={{ flex: 1 }}>
						<Typography variant="h3" sx={{ fontWeight: 'bold', mb: 2 }}>
							{course.title}
						</Typography>
						<Chip label={course.category} color="secondary" />
						<Typography variant="body1" sx={{ mt: 2 }}>
							{course.description}
						</Typography>
					</Box>
					<Box ref={courseSignupRef} sx={{ flex: { md: '0 1 400px' }, width: '100%' }}>
						<CourseSignup course={course} />
					</Box>
				</Box>
			</Box>
		</Box>
	);
};
