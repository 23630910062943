const initialState = {
	productCategoryes: []
};

export const productCategoryReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_ALL_PRODUCT_CATEGORYES':
			console.log(action.payload);
			return { ...state, productCategoryes: action.payload };
		default:
			return state;
	}
};
