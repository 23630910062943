import { Box, Typography, Link } from '@mui/material';

export const UserAgreementPage = () => {
	return (
		<Box sx={{ p: 4 }}>
			<Typography variant="h2" gutterBottom component="div" sx={{ mb: 4 }}>
				Пользовательское соглашение
			</Typography>
			<Typography variant="body1" paragraph>
				Текст пользовательского соглашения. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ut sodales justo. Ut aliquet, dui vitae
				finibus lobortis, lorem ligula pretium odio, id tristique quam purus et nunc. Duis nec purus sed lorem mattis gravida. Nulla facilisi. Proin non
				nisi non urna suscipit fringilla. Integer consectetur nulla sit amet justo faucibus, sed cursus orci fermentum. Nam rhoncus a justo at
				facilisis.
			</Typography>
			<Typography variant="body1">
				Полный текст пользовательского соглашения доступен по следующей ссылке:{' '}
				<Link href="/user-agreement" target="_blank" rel="noopener">
					Пользовательское соглашение
				</Link>
				.
			</Typography>
		</Box>
	);
};
