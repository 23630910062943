import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import LanguageIcon from '@mui/icons-material/Language';
import MenuIcon from '@mui/icons-material/Menu';
import TelegramIcon from '@mui/icons-material/Telegram';
import TwitterIcon from '@mui/icons-material/Twitter';
import {
	AppBar,
	Toolbar,
	Typography,
	Button,
	Box,
	IconButton,
	Stack,
	Card,
	CardContent,
	Grid,
	CardMedia,
	CardActions,
	Paper,
	Divider,
	List,
	ListItem,
	Container
} from '@mui/material';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { Link } from 'react-router-dom';
import CourseCatalog from 'src/components/CoursePage/CourseCatalog';
import { CourseSignup } from 'src/components/CoursePage/CourseSignup';
import { SubscriptionForm } from 'src/components/SubscriptionForm';
import mainimage from 'src/images/1674458810_4.png';
import logo from 'src/images/logo.png';
import logos from 'src/images/logos.png';
import sert from 'src/images/tudenchestvo-fon.jpg';

// Styled components using MUI's styled utility
const Logo = styled('img')({
	height: '50px', // Adjust according to your logo's size
	marginRight: 'auto'
});

const CustomAppBar = styled(AppBar)(({ theme }) => ({
	backgroundColor: 'rgba(255, 255, 255, 0.5)', // Adjust alpha for semi-transparency
	color: theme.palette.text.primary,
	boxShadow: 'none',
	backdropFilter: 'blur(8px)' // Apply blur to the background elements behind the AppBar
}));

const RoundedButton = styled(Button)({
	borderRadius: '20px', // Rounded corners for the button
	backgroundColor: 'primary.main', // Use theme's primary color
	color: '#75138b', // White text color for better contrast

	'&:hover': {
		backgroundColor: 'primary.dark' // Darken on hover
	},
	textTransform: 'none' // Prevent uppercase transformation
});

const GradientButton = styled(RoundedButton)(({ theme }) => ({
	backgroundImage: 'linear-gradient(to right, #FF7E5F, #765285)', // Gradient from orange to purple
	color: 'white', // White text color for better contrast
	'&:hover': {
		backgroundImage: 'linear-gradient(to right, #f06d47, #694d7d)' // Slightly darker gradient on hover
	}
}));

export const Header = () => {
	return (
		<CustomAppBar position="static">
			<Toolbar>
				<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
					<Link to="/" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}>
						<Logo src={logo} alt="Oxygen Academy" />
						<Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
							Oxygen Academy
						</Typography>
					</Link>
					<Stack direction="row" spacing={2}>
						<RoundedButton component={Link} to="/register">
							Регистрация
						</RoundedButton>
						<GradientButton component={Link} to="/login">
							Войти
						</GradientButton>
					</Stack>
				</Box>
			</Toolbar>
		</CustomAppBar>
	);
};

const MainBanner = ({ handleScrollToPay }) => {
	const commonButtonStyle = {
		padding: '10px 30px',
		textTransform: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '1rem',
		minWidth: { md: '160px' }, // Минимальная ширина кнопки на десктопе
		borderRadius: '20px', // Делаем кнопки округлыми
		mt: 2, // Добавляем отступ сверху для кнопки на мобильных
		ml: { md: 2 } // Добавляем отступ слева для кнопок на десктопе
	};

	return (
		<Box
			sx={{
				position: 'relative',
				p: 4,
				textAlign: 'center',
				backdropFilter: 'blur(10px)',
				backgroundImage: `url(${mainimage})`,
				backgroundSize: { xs: 'cover', md: 'auto 80%' },
				backgroundPosition: { xs: 'center center', md: 'right bottom' },
				backgroundRepeat: 'no-repeat'
			}}>
			<Container
				sx={{
					backgroundColor: 'rgba(255, 255, 255, 0.7)', // semi-transparent white background
					borderRadius: '8px', // optional border radius for a card-like appearance
					display: 'inline-block', // keeps the container size as per content
					p: 2 // padding inside the container
				}}>
				<Typography variant="h3" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: 'black' }}>
					Множество курсов - одна подписка!
				</Typography>
				<Typography variant="h6" component="h2" sx={{ mb: 3, color: 'black' }}>
					Обучайтесь онлайн в различных дисциплинах!
				</Typography>
				<Box
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center'
					}}>
					<Button
						variant="contained"
						onClick={handleScrollToPay}
						sx={{ ...commonButtonStyle, backgroundColor: 'secondary.main', '&:hover': { backgroundColor: 'secondary.dark' } }}>
						Начать сейчас
					</Button>
				</Box>
			</Container>
		</Box>
	);
};

const CertificateShowcase = ({ handleScrollToSearch }) => {
	// Replace with your image path for the certificate
	const certificateImage = '/path-to-your-certificate-image.jpg';

	return (
		<Box
			sx={{
				position: 'relative',
				p: 4,
				textAlign: 'center',
				backdropFilter: 'blur(10px)',
				backgroundImage: `url(${sert})`,
				backgroundSize: { xs: 'cover', md: 'auto 80%' },
				backgroundPosition: { xs: 'center center', md: 'right bottom' },
				backgroundRepeat: 'no-repeat'
			}}>
			<Container
				sx={{
					backgroundColor: 'rgba(255, 255, 255, 0.7)', // semi-transparent white background
					borderRadius: '8px', // optional border radius for a card-like appearance
					display: 'inline-block', // keeps the container size as per content
					p: 2 // padding inside the container
				}}>
				<Typography variant="h4" component="h2" gutterBottom sx={{ fontWeight: 'bold', color: 'primary.main' }}>
					Именной сертификат
				</Typography>
				<Typography sx={{ color: 'text.secondary' }}>
					После успешного завершения любой из наших программ вы получите именной сертификат, который будет являться подтверждением ваших навыков.
				</Typography>
				<Button
					onClick={handleScrollToSearch}
					variant="contained"
					sx={{
						mt: 2,
						borderRadius: '50px',
						padding: '10px 30px',
						fontSize: '1rem',
						backgroundColor: 'secondary.main',
						'&:hover': { backgroundColor: 'secondary.dark' },
						textTransform: 'none'
					}}>
					Выбрать курс
				</Button>
			</Container>
			{/* Feature cards */}
			{/* ... other components */}
		</Box>
	);
};

export const WhyTopRated = () => {
	// Placeholder data for the features
	const features = [
		{
			title: 'Удобный личный кабинет',
			description: 'Учитесь в любое время прямо с телефона. А удобный личный кабинет сделает ваше обучение еще комфортнее.'
			// Add link or onClick handler
		},
		{
			title: 'Растущая база программ',
			description: 'Мы регулярно пополняем каталог курсов и пробуем новые направления.'
			// Add link or onClick handler
		},
		{
			title: 'Все курсы сразу',
			description: 'У вас нет необходимости покупать каждый курс отдельно. Вы получаете доступ ко всей платформе сразу'
			// Add link or onClick handler
		}
		// ... Add more features as necessary
	];

	return (
		<Box sx={{ flexGrow: 1, p: 4, backgroundColor: 'rgba(255, 255, 255, 0.85)' }}>
			<Typography variant="h4" gutterBottom component="div" sx={{ mb: 4, fontWeight: 'bold', color: 'primary.main' }}>
				Почему Oxygen Academy — топ?
			</Typography>
			<Grid container spacing={2}>
				{features.map((feature, index) => (
					<Grid item xs={12} sm={6} md={4} key={index}>
						<Card
							sx={{
								maxWidth: 345,
								minHeight: '100%', // Ensure all cards have the same minimum height
								boxShadow: 3,
								borderRadius: '10px',
								overflow: 'hidden',
								backgroundColor: 'rgba(255, 255, 255, 0.9)',
								'&:hover': {
									boxShadow: '0px 6px 25px rgba(0,0,0,0.15)',
									transform: 'scale(1.02)'
								}
							}}>
							<CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
								<Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: 'medium', color: 'secondary.main' }}>
									{feature.title}
								</Typography>
								<Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
									{feature.description}
								</Typography>
							</CardContent>
						</Card>
					</Grid>
				))}
			</Grid>
		</Box>
	);
};

const CombinedCards = ({ handleScrollToSearch, handleScrollToPay }) => {
	const telegramLink = 'https://t.me/YourTelegramChannel';
	const commonButtonStyle = {
		padding: '10px 30px',
		textTransform: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '1rem',
		width: '100%', // Растягиваем кнопку на всю ширину на мобильных
		minWidth: { md: '160px' }, // Минимальная ширина кнопки на десктопе
		borderRadius: '20px', // Делаем кнопки округлыми
		mt: 2, // Добавляем отступ сверху для кнопки на мобильных
		ml: { md: 2 } // Добавляем отступ слева для кнопок на десктопе
	};

	const commonCardStyle = {
		maxWidth: '100%',
		boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.2)',
		p: 3,
		borderRadius: '20px', // Делаем карточки округлыми
		display: 'flex',
		flexDirection: { xs: 'column', md: 'row' }, // Вертикальное расположение на мобильных, горизонтальное на десктопах
		justifyContent: 'space-between',
		textAlign: { xs: 'center', md: 'left' }
	};

	return (
		<Grid container spacing={2} sx={{ p: 4, justifyContent: 'center' }}>
			{/* Первый элемент */}
			<Grid item xs={12}>
				<Card sx={commonCardStyle}>
					<CardContent sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center' }}>
						<Box sx={{ flex: '1', mb: { xs: 2, md: 0 } }}>
							<Typography variant="h5" sx={{ fontWeight: 'bold' }}>
								Специальное предложение
							</Typography>
							<Typography variant="body1" sx={{ my: 2 }}>
								Доступ ко всем курсам всего за 2.99$ в неделю
							</Typography>
						</Box>
						<Button
							variant="contained"
							onClick={handleScrollToPay}
							sx={{ ...commonButtonStyle, backgroundColor: 'secondary.main', '&:hover': { backgroundColor: 'secondary.dark' } }}>
							Подписаться сейчас
						</Button>
					</CardContent>
				</Card>
			</Grid>

			<Grid item xs={12}>
				<Card sx={commonCardStyle}>
					<CardContent sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center' }}>
						<Box sx={{ flex: '1', mb: { xs: 2, md: 0 } }}>
							<Typography variant="h5" sx={{ fontWeight: 'bold' }}>
								Поддержка 24/7
							</Typography>
							<Typography variant="body1" sx={{ my: 2 }}>
								Наша команда всегда готова вам помочь
							</Typography>
						</Box>
						<Button
							variant="contained"
							href={telegramLink}
							target="_blank"
							rel="noopener noreferrer"
							sx={{ ...commonButtonStyle, backgroundColor: 'primary.main', '&:hover': { backgroundColor: 'primary.dark' } }}
							startIcon={<TelegramIcon sx={{ color: 'white' }} />}>
							Связаться в Telegram
						</Button>
					</CardContent>
				</Card>
			</Grid>

			<Grid item xs={12}>
				<Card sx={commonCardStyle}>
					<CardContent sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center' }}>
						<Box sx={{ flex: '1', mb: { xs: 2, md: 0 } }}>
							<Typography variant="h5" sx={{ fontWeight: 'bold' }}>
								Присоединяйтесь сейчас
							</Typography>
							<Typography variant="body1" sx={{ my: 2 }}>
								Начните свой путь обучения сегодня
							</Typography>
						</Box>
						<Button
							onClick={handleScrollToSearch}
							variant="contained"
							sx={{ ...commonButtonStyle, backgroundColor: 'secondary.main', '&:hover': { backgroundColor: 'secondary.dark' } }}>
							Изучить курсы
						</Button>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};

const Footer = () => {
	return (
		<Box sx={{ bgcolor: 'white', color: 'black', p: 4, boxShadow: '0 -4px 10px rgba(0, 0, 0, 0.1)' }}>
			<Container>
				<Grid container spacing={4} justifyContent="space-between">
					{/* Left Column */}
					<Grid item xs={12} md={6} sx={{ p: 3, bgcolor: 'background.paper', borderRadius: 2 }}>
						<Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: 'primary.main' }}>
							О нас
						</Typography>
						<Divider sx={{ bgcolor: 'primary.light', mb: 2 }} />
						<Typography variant="body1" gutterBottom>
							Общество с ограниченной ответственностью &quot;ЦИТРУСКОМ&quot;
						</Typography>
						<Typography variant="body2" gutterBottom>
							УНП 193699866
						</Typography>
						<Typography variant="body2" gutterBottom>
							Регистрация: 25 июля 2023г. Минский горисполком
						</Typography>
						<Typography variant="body2" gutterBottom sx={{ mb: 1 }}>
							Почтовый/юридический адрес: 220013, ГОРОД МИНСК, УЛИЦА 2 ШЕСТАЯ ЛИНИЯ ДОМ 11 КВАРТИРА 6А-8-120
						</Typography>
						<Typography variant="body2" gutterBottom>
							Режим работы: с 9:00 до 16:00
						</Typography>
						<Typography variant="body2" gutterBottom>
							Email: oxygen.academy.info@gmail.com
						</Typography>
						<Typography variant="body2" gutterBottom>
							Телефон для связи: +375336389280
						</Typography>
					</Grid>

					{/* Right Column */}
					<Grid item xs={12} md={6}>
						{/* Here you can add additional information or links */}
					</Grid>
				</Grid>

				{/* Logo and Payment System Logos */}
				<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4 }}>
					<img src={logo} alt="Oxygen Academy Logo" style={{ maxWidth: '100px', marginBottom: '20px' }} />
					<img src={logos} alt="Payment System Logos" style={{ maxWidth: '90%', maxHeight: '100px', objectFit: 'contain' }} />
				</Box>

				<Box sx={{ textAlign: 'center', mt: 3 }}>
					<Typography variant="body2" color="text.secondary">
						© 2024 Oxygen Academy. Все права защищены.
					</Typography>
					<Box sx={{ mt: 1 }}>
						<Link to="/oferta" color="inherit" sx={{ mx: 2 }}>
							Публичная оферта
						</Link>{' '}
						<Link to="/konfidence" color="inherit" sx={{ mx: 2 }}>
							Политика конфиденциальности
						</Link>
					</Box>
				</Box>
			</Container>
		</Box>
	);
};

export const MainPage = () => {
	const searchRef = React.useRef(null);
	const payRef = React.useRef(null);

	const handleScrollToSearch = () => {
		searchRef.current.scrollIntoView({ behavior: 'smooth' });
	};

	const handleScrollToPay = () => {
		payRef.current.scrollIntoView({ behavior: 'smooth' });
	};
	return (
		<>
			<Container>
				<MainBanner handleScrollToPay={handleScrollToPay} />
				<CourseCatalog ref={searchRef} />
				<CertificateShowcase handleScrollToSearch={handleScrollToSearch} />
				<CourseSignup ref={payRef} />

				<WhyTopRated />

				{/* <CombinedCards handleScrollToSearch={handleScrollToSearch} handleScrollToPay={handleScrollToPay} /> */}
			</Container>
			<Footer></Footer>
		</>
	);
};
