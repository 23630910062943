import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { Box, Grid, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useAuth } from 'src/context/AuthService';
import { users as usersAPI } from 'src/context/RestService';

const calculateFileSize = (base64String) => {
	let padding, inBytes, base64StringLength;
	if (base64String.endsWith('==')) {
		padding = 2;
	} else if (base64String.endsWith('=')) {
		padding = 1;
	} else {
		padding = 0;
	}

	base64StringLength = base64String.length;
	inBytes = (base64StringLength / 4) * 3 - padding;
	const inKb = inBytes / 1024;
	const inMb = inKb / 1024;
	return inMb.toFixed(2);
};

export const ProfileSettings = () => {
	const { user } = useAuth();

	const [editedUser, setEditedUser] = useState({ ...user });
	const [avatarPreview, setAvatarPreview] = useState(user.avatar);
	const [isPhotoDialogOpen, setIsPhotoDialogOpen] = useState(false);

	const togglePhotoDialog = () => {
		setIsPhotoDialogOpen(!isPhotoDialogOpen);
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setEditedUser({ ...editedUser, [name]: value });
	};
	const handleAvatarChange = (event) => {
		const file = event.target.files[0];

		if (file) {
			const fileReader = new FileReader();
			fileReader.onload = (e) => {
				// Создаем новый объект Image
				const img = new Image();
				img.src = e.target.result;
				img.onload = () => {
					const canvas = document.createElement('canvas');
					const ctx = canvas.getContext('2d');

					canvas.width = img.width;
					canvas.height = img.height;

					let width = img.width;
					let height = img.height;
					const MAX_WIDTH = 1920;
					const MAX_HEIGHT = 1080;

					if (width > MAX_WIDTH) {
						height *= MAX_WIDTH / width;
						width = MAX_WIDTH;
					}

					if (height > MAX_HEIGHT) {
						width *= MAX_HEIGHT / height;
						height = MAX_HEIGHT;
					}

					canvas.width = width;
					canvas.height = height;

					ctx.drawImage(img, 0, 0, width, height);

					const dataUrl = canvas.toDataURL('image/jpeg', 0.92);

					setAvatarPreview(dataUrl);

					const base64String = dataUrl.split(',')[1];
					setEditedUser({ ...editedUser, avatarBase64: base64String });
				};
			};
			fileReader.readAsDataURL(file);
		}
	};

	const handleSave = async () => {
		const updateData = {};

		if (editedUser.avatarBase64) {
			updateData.avatarBase64 = editedUser.avatarBase64;
		}

		if (editedUser.newPassword && editedUser.confirmPassword) {
			if (editedUser.newPassword === editedUser.confirmPassword) {
				updateData.newPassword = editedUser.newPassword;
				updateData.confirmPassword = editedUser.confirmPassword;
			} else {
				enqueueSnackbar('Пароли не совпадают', { variant: 'error' });
				return;
			}
		} else if (editedUser.newPassword || editedUser.confirmPassword) {
			enqueueSnackbar('Необходимо заполнить оба поля пароля', { variant: 'warning' });
			return;
		}

		try {
			if (Object.keys(updateData).length > 0) {
				await usersAPI.update(updateData, user.id);
				enqueueSnackbar('Профиль успешно обновлён', { variant: 'success' });
			} else {
				enqueueSnackbar('Нет изменений для сохранения', { variant: 'info' });
			}
		} catch (error) {
			if (error.response) {
				switch (error.response.status) {
					case 400:
						if (error.response.data && error.response.data.errors) {
							Object.values(error.response.data.errors).forEach((value) => {
								enqueueSnackbar(`${value}`, { variant: 'error' });
							});
						}
						break;
					case 401:
						enqueueSnackbar('Доступ запрещен: Неавторизован', { variant: 'warning' });
						break;
					case 500:
						enqueueSnackbar(`${error.response.data}`, { variant: 'error' });
						break;
					default:
						enqueueSnackbar('Произошла непредвиденная ошибка', { variant: 'error' });
				}
			} else if (error.request) {
				enqueueSnackbar('Ошибка сети: Не удалось выполнить запрос', { variant: 'error' });
			} else {
				enqueueSnackbar('Ошибка: Неизвестная ошибка', { variant: 'error' });
			}
		}
	};

	const handleCancelSubscription = async () => {
		// Здесь должен быть код для отмены подписки пользователя
		enqueueSnackbar('Подписка отменена', { variant: 'info' });
	};

	return (
		<Box sx={{ p: 4 }}>
			<Grid container spacing={3} justifyContent="center" alignItems="center">
				<Grid item xs={12} sm={6} md={4} lg={3}>
					<Box sx={{ textAlign: 'center' }}>
						<Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 1 }}>
							<Avatar src={avatarPreview} sx={{ width: 120, height: 120, mb: 1 }} onClick={togglePhotoDialog} />

							<input
								accept=".jpeg,.jpg,.png,.gif,.bmp"
								id="avatar-upload"
								type="file"
								onChange={handleAvatarChange}
								style={{ display: 'none' }}
							/>
							<label htmlFor="avatar-upload" style={{ cursor: 'pointer' }}>
								<Button size="small" variant="text" component="span" onChange={handleAvatarChange} sx={{ marginTop: '8px' }}>
									Обновить фотографию
								</Button>
							</label>
						</Grid>
					</Box>
				</Grid>

				<Grid item xs={12} sm={6} md={8} lg={9}>
					<Typography variant="h6">{editedUser.name}</Typography>
					<Typography variant="body1">{editedUser.email}</Typography>
					<Typography variant="body1">{editedUser.phone}</Typography>
					<TextField
						size="small"
						margin="dense"
						name="newPassword"
						label="Новый пароль"
						type="password"
						fullWidth
						variant="outlined"
						value={editedUser.newPassword || ''}
						onChange={handleChange}
						sx={{ my: 1 }}
					/>
					<TextField
						size="small"
						margin="dense"
						name="confirmPassword"
						label="Подтвердите пароль"
						type="password"
						fullWidth
						variant="outlined"
						value={editedUser.confirmPassword || ''}
						onChange={handleChange}
						sx={{ my: 1 }}
					/>
					<Button variant="contained" color="primary" onClick={handleSave} sx={{ mt: 2 }}>
						Сохранить изменения
					</Button>
				</Grid>
			</Grid>

			<Dialog open={isPhotoDialogOpen} onClose={togglePhotoDialog}>
				<DialogTitle>Просмотр фотографии</DialogTitle>
				<DialogContent>
					{user.avatar && (
						<Box sx={{ textAlign: 'center' }}>
							<img src={avatarPreview} alt="Avatar" style={{ maxWidth: '100%', maxHeight: '400px' }} />
							<Typography variant="body1">Размер: {calculateFileSize(user.avatar)} MB</Typography>
						</Box>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={togglePhotoDialog}>Закрыть</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};
